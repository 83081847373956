/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  Modal, Button, Row, Col, 
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { displayAdminRoles } from '../../redux/roles/roleActions';
import {
  getCRMUser, getUplineUsers, editCRMUser, updateCRMUserState,
} from '../../redux/crmUser/crmUserActions';
import { displayModulesWithPermissions } from '../../redux/permissionsModule/permissionsModuleActions';
import {
  addPermissionTemplate, getAllPermissionTemplates, deletePermissionTemplate, updatePermissionTemplateState, 
} from '../../redux/permissions/permissionActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';
import { CRMUSerActivity } from './CRMUserDetailComponents/CRMUserActivity';
import { Voip } from './CRMUserDetailComponents/Voip';
import { InputPassword } from '../../components';

let rolesOptions = [];

function EditCRMUser() {
  const { id } = useParams();
  const userId = localStorage.getItem('userId');
  const storageUserId = JSON.parse(userId);
  const dispatch = useDispatch();
  const history = useHistory();

  const [loader, setLoader] = useState(false);
  const [user, setUser] = useState('');
  const [selectedRole, setSelectedRole] = useState(null);
  const [allUplineUsersOptions, setAllUplineUsersOptions] = useState([]);
  const [selectedDefaultManager, setSelectedDefaultManager] = useState(null);
  const [salesRole, setSalesRole] = useState(1);
  const [modulesWithPermissions, setModulesWithPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [isModalShow, setModalShown] = useState(false);
  const [userStatus, setUserStatus] = useState(true);
  const [activeTab, setActiveTab] = useState('personal');

  const roles = useSelector((state) => state.role?.adminRoles);
  const fetched = useSelector((state) => state.role?.fetchedAdminRoles);
  const userData = useSelector((state) => state.crmUser?.crmUser);
  const uplineUsers = useSelector((state) => state.crmUser?.uplineUsers);
  const crmUserFetched = useSelector((state) => state.crmUser?.crmUserFetched);
  const modulesWithPermissionsData = useSelector((state) => state.permissionsModule?.modulesWithPermissions);
  const permissionTemplates = useSelector((state) => state.permission.permissionTemplates);
  const permissionTemplate = useSelector((state) => state.permission.permissionTemplate);
  const updatePermissionTemplate = useSelector((state) => state.permission.updatePermissionTemplate);
  const {
    register, handleSubmit, control, reset, formState: { errors },
  } = useForm();
  const {
    // eslint-disable-next-line no-unused-vars
    register: register1, handleSubmit: handleSubmit1, control: control1, reset: reset1, formState: { errors: errors1 },
  } = useForm();

  useEffect(() => {
    reset();
    setLoader(true);
    dispatch(getCRMUser(id));
    dispatch(displayAdminRoles());
    dispatch(displayModulesWithPermissions());
    dispatch(getAllPermissionTemplates());
  }, [id]);

  useEffect(() => {
    if (Object.keys(userData)?.length > 0 && userData._id === id && crmUserFetched) {
      setUser(userData);
      dispatch(updateCRMUserState());
      if (userData?.roleId) {
        dispatch(getUplineUsers(id, userData?.roleId));
      }
      if (userData?.salesRole) {
        setSalesRole(userData?.salesRole);
      }

      setUserStatus(userData.status);
    }
  }, [userData, crmUserFetched]);

  useEffect(() => {
    if (modulesWithPermissionsData?.length > 0) {
      setModulesWithPermissions(modulesWithPermissionsData);
    }
  }, [modulesWithPermissionsData]);

  useEffect(() => {
    const fetchData = async () => {
      if (Object.keys(userData)?.length > 0 && modulesWithPermissions?.length > 0) {
        const perms = userData?.permissions?.map((permission) => permission._id);
        setSelectedPermissions([...perms]);
      }
    };
    fetchData();
  }, [userData, modulesWithPermissions]);

  const handlePermissionCheck = (selectedId) => {
    // If the checkbox was previously checked, remove it from the list of checked checkboxes
    // Otherwise, add it to the list
    if (selectedPermissions.includes(selectedId)) {
      setSelectedPermissions(selectedPermissions.filter((item) => item !== selectedId));
    } else {
      setSelectedPermissions([...selectedPermissions, selectedId]);
    }
  };

  const handleModuleCheck = (parentId) => {
    const parent = modulesWithPermissions.find((item) => item._id === parentId);
    const childrenIds = parent.permissions.map((permission) => permission._id);
    // If all child checkboxes are currently checked, uncheck them
    // Otherwise, check them
    if (childrenIds.every((childId) => selectedPermissions.includes(childId))) {
      setSelectedPermissions(selectedPermissions.filter((item) => !childrenIds.includes(item)));
    } else {
      setSelectedPermissions([...selectedPermissions, ...childrenIds]);
    }
  };
  
  // ============ PERMISSION TEMPLATES ============

  useEffect(() => {
    if (updatePermissionTemplate && permissionTemplate?._id) {
      setSelectedTemplate(permissionTemplate._id);
      dispatch(updatePermissionTemplateState());
    }
  }, [updatePermissionTemplate, permissionTemplate?._id]);

  const getPermissionIds = () => modulesWithPermissions.flatMap((modWithPerm) => modWithPerm.permissions.map((perm) => perm._id));

  const handleSelectAll = () => {
    const permissionIds = getPermissionIds();
    setSelectedPermissions(permissionIds);
  };

  const handleUnselectAll = () => setSelectedPermissions([]);

  const selectedTemplatePermissions = (permId) => {
    const permissionIds = new Set(getPermissionIds());
    const templatePermissionIds = [...permissionIds].filter((permissionId) => permissionTemplates.some((template) => template._id === permId && template.permissions.includes(permissionId)));
    setSelectedPermissions(templatePermissionIds);
  };

  const handleChangeTemplates = (e) => {
    const { value } = e.target;
    setSelectedTemplate(value);
    switch (value) {
      case 'selectAll':
        handleSelectAll();
        break;
      case 'unselectAll':
        handleUnselectAll();
        break;
      case 'add':
        setModalShown(true);
        break;
      default:
        selectedTemplatePermissions(value);
    }
  };

  const handleTemplateNameChange = (e) => {
    setTemplateName(e.target.value);
  };

  const onCreateTemplate = () => {
    if (!templateName) {
      toast.error('Enter a Template Name.', {
        autoClose: 1000,
      });

      return;
    }

    const data = {
      name: templateName,
      permissions: selectedPermissions || [],
    };
    dispatch(addPermissionTemplate(data));
    setTemplateName('');
    setModalShown(false);
  };

  const confirmDeleteTemplate = () => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deletePermissionTemplate(selectedTemplate));
        setSelectedTemplate('');
        setSelectedPermissions([]);
      }
    });
  };

  const onDeleteTemplate = () => {
    if (!selectedTemplate || ['selectAll', 'unselectAll', 'add'].includes(selectedTemplate)) {
      toast.error('Please select a valid template.', {
        autoClose: 1000,
      });
    } else {
      confirmDeleteTemplate();
    }
  };

  // ============ END PERMISSION TEMPLATES ============

  const filterUplineUsers = async (currentSalesRole) => {
    const uplineUsersOptions = await uplineUsers?.filter((elem) => Number(elem?.salesRole) === Number(currentSalesRole) || Number(elem?.salesRole) === 3)?.map((uplineUser) => ({ value: uplineUser._id, label: `${uplineUser.firstName} ${uplineUser.lastName}` }));

    setAllUplineUsersOptions(uplineUsersOptions);

    if (uplineUsersOptions && uplineUsersOptions.length > 0 && user?.defaultManager) {
      const currUplineUser = await uplineUsersOptions.find((stat) => stat.value === user?.defaultManager);
      if (currUplineUser) {
        setSelectedDefaultManager(currUplineUser);
      } else {
        setSelectedDefaultManager(null);
      }
    } else {
      setSelectedDefaultManager(null);
    }
  };

  useEffect(() => {
    filterUplineUsers(salesRole);
  }, [uplineUsers, user]);

  useEffect(() => {
    const fetchData = async () => {
      if (user && roles?.length > 0) {
        rolesOptions = await roles?.map((role) => ({ value: role._id, label: role.name }));

        if (user?.roleId) {
          const currRole = rolesOptions.find((rol) => rol.value === user?.roleId);
          setSelectedRole(currRole);
        } else {
          setSelectedRole(rolesOptions?.[0]);
        }
      }

      if (fetched) setLoader(false);
    };
    fetchData();
  }, [roles, user, fetched]);

  const handleRoleChange = (selectedRoleChange) => {
    setSelectedRole(selectedRoleChange);
    dispatch(getUplineUsers(id, selectedRoleChange?.value));
  };

  const handleUplineUserChange = (selectedUplineUser) => {
    setSelectedDefaultManager(selectedUplineUser);
  };

  const handleSalesRoleChange = async (e) => {
    setSalesRole(e.target.value);
    filterUplineUsers(e.target.value);
  };

  const handleSave = async (formData) => {
    setLoader(true);
    const data = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      // phone: formData.phone,
      roleId: selectedRole.value,
      status: userStatus,
      salesRole,
    };
    if (selectedDefaultManager) {
      data.defaultManager = selectedDefaultManager?.value;
    }
    await dispatch(editCRMUser(id, data));
    history.goBack();
  };

  const handlePermissionSave = () => {
    if (user?._id === storageUserId) Swal.errors('Forbidden');
    const data = {
      permissionIds: selectedPermissions,
    };
    dispatch(editCRMUser(id, data));
  };

  const handlePasswordChange = (formData) => {
    setLoader(true);
    const data = {
      password: formData.password,
    };
    dispatch(editCRMUser(id, data, false, false));
    setLoader(false);
  };

  const editSubAdmin = {
    firstName: {
      required: 'First name is required',
    },
    lastName: {
      required: 'Last name is required',
    },
    // phone: {
    //    required: "Phone Number is required",
    //    pattern: {
    //       value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
    //       message: 'Please enter a valid contact number',
    //    }
    // }
  };

  const editSubAdminPassword = {
    password: {
      required: 'Password is required',
      minLength: {
        value: 6,
        message: 'Password must have at least 6 characters',
      },
    },
  };

  const handleChangeUserStatus = (userStatus) => {
    setUserStatus(userStatus);
  };

  const colourStyles = {
    control: (styles, { isSelected, isDisabled }) => ({
      ...styles,
      background: '#374057',
      color: '#fff',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      border: '1px solid #374057',
      boxShadow: isSelected ? 'none' : 'none',
      borderColor: isSelected ? '#374057' : '#374057',
      '&:hover': {
        boxShadow: 'none',
      },
    }),
    input: (styles) => ({
      ...styles,
      color: '#fff',
    }),
    singleValue: (styles) => ({
      ...styles,
      color: '#fff',
    }),
    menuList: (styles) => ({
      ...styles,
      background: '#374057',
    }),
    option: (styles, { isFocused, isSelected, isDisabled }) => ({
      ...styles,
      background: isFocused
        ? '#16202e'
        : isSelected
          ? '#16202e'
          : undefined,
      color: '#fff',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      zIndex: 1,
      '&:hover': {
        background: '#16202e',
      },
    }),
  };

  const convertPermissionName = (str) => str.replace(/_./g, (match) => ` ${match.charAt(1).toUpperCase()}`);

  return (
    loader ? (<FullPageTransparentLoader />) : user && user ? (
      <div className="content-wrapper right-content-wrapper crm-tabs-holder">
        <div className="content-box">
          <FontAwesomeIcon className="faArrowLeftIcon" icon={faArrowLeft} onClick={() => history.goBack()} />
          <ul className="nav nav-tabs mb-3" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                type="button"
                className="nav-link text-capitalize"
                style={{ backgroundColor: activeTab === 'personal' ? '#374057' : '', color: 'white' }}
                onClick={() => setActiveTab('personal')}
              >
                Personal
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                type="button"
                className="nav-link text-capitalize"
                onClick={() => setActiveTab('activity')}
                style={{ backgroundColor: activeTab === 'activity' ? '#374057' : '', color: 'white' }}
              >
                Activity
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                type="button"
                className="nav-link text-capitalize"
                onClick={() => setActiveTab('voip')}
                style={{ backgroundColor: activeTab === 'voip' ? '#374057' : '', color: 'white' }}
              >
                VOIP
              </button>
            </li>
            {/* <li className="nav-item" role="presentation">
              <button className="nav-link text-capitalize" id="security-tab" data-bs-toggle="tab" data-bs-target="#security" type="button" role="tab" aria-controls="security" aria-selected="false">
                security
              </button>
            </li> */}
          </ul>
          { activeTab === 'personal' ? (
            <div>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="tab-pane fade show active tab-main-box" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <h3 className="text-capitalize">Edit CRM User</h3>
                    <form onSubmit={handleSubmit(handleSave)}>
                      <div className="form-group col-md-12">
                        <label className="control-label">User ID</label>
                        <input
                          type="text"
                          className="form-control"
                          value={user.customId}
                          disabled
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <label className="control-label">First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter First name"
                          {...register('firstName', editSubAdmin.firstName)}
                          name="firstName"
                          control={control}
                          defaultValue={user?.firstName}
                        />
                        {errors?.firstName && <span className="errMsg">{errors.firstName.message}</span>}
                      </div>
                      <div className="form-group col-md-12 pt-2">
                        <label className="control-label">Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Last name"
                          {...register('lastName', editSubAdmin.lastName)}
                          name="lastName"
                          control={control}
                          defaultValue={user?.lastName}
                        />
                        {errors?.lastName && <span className="errMsg">{errors.lastName.message}</span>}
                      </div>
                      <div className="form-group col-md-12 pt-2">
                        <label className="control-label">User Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Username"
                          {...register('username', editSubAdmin.username)}
                          name="username"
                          control={control}
                          defaultValue={user?.username}
                          disabled
                        />
                        {errors?.username && <span className="errMsg">{errors.username.message}</span>}
                      </div>
                      <div className="form-group col-md-12 pt-2">
                        <label className="control-label">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter Email"
                          {...register('email', editSubAdmin.email)}
                          name="email"
                          control={control}
                          defaultValue={user?.email}
                          disabled
                        />
                        {errors?.email && <span className="errMsg">{errors.email.message}</span>}
                      </div>
                      {/* <div className="form-group col-md-12 pt-2">
                      <label className="control-label">Phone Number</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Phone number"
                        name="phone"
                        defaultValue={user?.phone}
                        {...register('phone', editSubAdmin.phone)}
                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                      />
                      {errors?.phone && <span className="errMsg">{errors.phone.message}</span>}
                    </div> */}
                      <div className="form-group col-md-12 pt-2">
                        <label className="control-label">Role</label>
                        <Select
                          value={selectedRole}
                          onChange={handleRoleChange}
                          options={rolesOptions}
                          styles={colourStyles}
                        />
                      </div>
                      <div className="form-group col-md-12 pt-2">
                        <label className="control-label">Sales Role</label>
                        <select className="form-control user-status-select" name="type" value={salesRole} onChange={(e) => handleSalesRoleChange(e)}>
                          <option value={1}>Sales</option>
                          <option value={2}>Retention</option>
                          <option value={3}>Both</option>
                        </select>
                      </div>
                      {selectedRole?.label !== 'Admin' ? (
                        <div className="form-group col-md-12 pt-2">
                          <label className="control-label">{selectedRole?.label === 'Agent' ? 'Manager' : 'Upline'}</label>
                          <Select
                            value={selectedDefaultManager}
                            onChange={handleUplineUserChange}
                            options={allUplineUsersOptions}
                            styles={colourStyles}
                          />
                        </div>
                      ) : null}
                      <div className="form-group col-md-12 pt-2">
                        <label className="control-label">Enable</label>
                        <select className="form-control user-status-select" name="type" value={userStatus} onChange={(e) => handleChangeUserStatus(e.target.value)}>
                          <option value>Yes</option>
                          <option value={false}>No</option>
                        </select>
                      </div>
                      <div className="form-group col-md-12 pt-2">
                        <label className="control-label" htmlFor="referral-code">Referral Code</label>
                        <input className="form-control user-status-select" id="referral-code" value={userData.referralCode ?? 'This CRM User has no referral code'} readonly />
                      </div>
                      <div>
                        <button className="btn btn-default" type="submit">Update Personal Details</button>
                      </div>
                    </form>
                    <br />
                    <h5 className="text-capitalize" style={{ marginBottom: 5 }}>Change Password</h5>
                    <form onSubmit={handleSubmit1(handlePasswordChange)}>
                      <div className="form-group col-md-12 pt-2">
                        <label className="control-label">New Password</label>
                        <InputPassword
                          placeholder="Enter Password"
                          name="password"
                          register={{ ...register1('password', editSubAdminPassword.password) }}
                          control={control1}
                        />
                        {errors1?.password && <span className="errMsg">{errors1.password.message}</span>}
                      </div>
                      <div>
                        <button className="btn btn-default" type="submit">Change Password</button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="tab-pane fade show active tab-main-box" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div className="heading-container mb-3">
                      <h3 className="text-capitalize">Permissions</h3>
                      <div className="form-group form rights">
                        <div className="row">
                          <div className="col-md-8">
                            <select
                              className="form-control user-status-select leads-status-select"
                              name="templates"
                              onChange={handleChangeTemplates}
                              value={selectedTemplate}
                            >
                              {!selectedTemplate
                              && <option value="">Choose template</option>}
                              {permissionTemplates?.map((permissionTemplate) => (
                                <option key={permissionTemplate._id} value={permissionTemplate._id}>
                                  {permissionTemplate.name}
                                </option>
                              ))}
                              <option value="selectAll">Select All</option>
                              <option value="unselectAll">Unselect All</option>
                              <option value="add">Add...</option>
                            </select>
                          </div>
                          <div className="col-md-4 form-actions">
                            <button className="btn btn-danger" type="button" onClick={onDeleteTemplate}>
                              Remove template
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form rights">
                      <div className="two-cols">
                        <div className="onecol col-left">
                          {modulesWithPermissions?.length > 0 ? modulesWithPermissions?.filter((a, i) => !(i % 2))?.map((modWithPerm) => (
                            <div className="right-group" key={`permmod_${modWithPerm?._id}`}>
                              <div className="right-header">
                                <label htmlFor={`checkbox-${modWithPerm.name}`}>
                                  <input
                                    type="checkbox"
                                    id={`checkbox-${modWithPerm.name}`}
                                    checked={modWithPerm.permissions.every((permission) => selectedPermissions.includes(permission._id))}
                                    onChange={() => handleModuleCheck(modWithPerm._id)}
                                  />
                                  <span className="checkmark" />
                                  <span className="right-readable">{modWithPerm.name}</span>
                                </label>
                              </div>
                              <div id={`${modWithPerm.name}_rights`} className="rights-area">
                                {modWithPerm?.permissions?.length > 0 && modWithPerm?.permissions?.map((perm) => (
                                  <div className="single-right-container" key={`perm_${perm?._id}`}>
                                    <label htmlFor={`right-${perm?.name}`} className="">
                                      <input
                                        type="checkbox"
                                        data-manager-right={perm?.name}
                                        id={`right-${perm?.name}`}
                                        value={perm?._id}
                                        checked={selectedPermissions.includes(perm._id)}
                                        onChange={() => handlePermissionCheck(perm._id)}
                                      />
                                      <span className="checkmark" />
                                      <span className="right-readable">{convertPermissionName(perm?.name)}</span>
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )) : null}
                        </div>
                        <div className="onecol col-right" style={{ paddingLeft: '1.25rem' }}>
                          {modulesWithPermissions?.length > 0 ? modulesWithPermissions?.filter((a, i) => (i % 2))?.map((modWithPerm) => (
                            <div className="right-group" key={`permmod_${modWithPerm?._id}`}>
                              <div className="right-header">
                                <label htmlFor={`checkbox-${modWithPerm.name}`}>
                                  <input
                                    type="checkbox"
                                    id={`checkbox-${modWithPerm.name}`}
                                    checked={modWithPerm.permissions.every((permission) => selectedPermissions.includes(permission._id))}
                                    onChange={() => handleModuleCheck(modWithPerm._id)}
                                  />
                                  <span className="checkmark" />
                                  <span className="right-readable">{modWithPerm.name}</span>
                                </label>
                              </div>
                              <div id={`${modWithPerm.name}_rights`} className="rights-area">
                                {modWithPerm?.permissions?.length > 0 && modWithPerm?.permissions?.map((perm) => (
                                  <div className="single-right-container" key={`perm_${perm?._id}`}>
                                    <label htmlFor={`right-${perm?.name}`} className="">
                                      <input
                                        type="checkbox"
                                        data-manager-right={perm?.name}
                                        id={`right-${perm?.name}`}
                                        checked={selectedPermissions.includes(perm._id)}
                                        onChange={() => handlePermissionCheck(perm._id)}
                                      />
                                      <span className="checkmark" />
                                      <span className="right-readable">{convertPermissionName(perm?.name)}</span>
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )) : null}
                        </div>
                      </div>
                      {user?._id === storageUserId ? null
                        : (
                          <div className="form-group form-actions">
                            <button className="btn btn-default" type="button" onClick={handlePermissionSave}>Update Rights</button>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null }
          { activeTab === 'activity' ? <CRMUSerActivity uid={user._id} /> : null}
          { activeTab === 'voip' ? <Voip uid={user._id} /> : null}
        </div>
        <Modal show={isModalShow} onHide={() => setModalShown(false)} className="widthdrawal-modal">
          <Modal.Header closeButton>
            <Modal.Title className="text-white">Save Permissions Template</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="withdrawal-data">
              <Row>
                <Col md={12}>
                  <div className="form-group me-2">
                    <label className="control-label mb-2">Template Name</label>
                    <div className="input-wrapper">
                      <input autoComplete="off" type="text" className="form-control" name="templateName" onChange={handleTemplateNameChange} />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalShown(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onCreateTemplate}>Add</Button>
          </Modal.Footer>
        </Modal>
      </div>
    ) : ''
  );
}

export default EditCRMUser;
