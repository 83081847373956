import { React, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { updateClosedCfdOrder } from '../../redux/cfdOrder/cfdOrderActions';

export function EditOrderModal({ order = {}, hideModal }) {
  const dispatch = useDispatch();
  const [updatedOrder, setUpdatedOrder] = useState({});

  useEffect(() => {
    const {
      tradeStartPrice, 
      tradeEndPrice,
      exitPrice, 
      position, 
      timeOpened, 
      closedAt, 
    } = order;

    setUpdatedOrder({
      tradeStartPrice,
      tradeEndPrice,
      position,
      exitPrice,
      timeOpened: moment(timeOpened).format('YYYY-MM-DD HH:mm:ss'),
      closedAt: moment(closedAt).format('YYYY-MM-DD HH:mm:ss'),
    });
  }, [order]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUpdatedOrder((prev) => ({ ...prev, [name]: value }));
  };

  const handleClose = () => {
    setUpdatedOrder({});
    hideModal();
  };

  const updateOrder = () => {
    if (!order._id) return;

    dispatch(updateClosedCfdOrder(order._id, updatedOrder));
    handleClose();
  };

  return (
    !!Object.keys(updatedOrder).length && (
    <>
      <div className="row mb-3">
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Price Open</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Price Open"
            aria-label=""
            aria-describedby=""
            value={updatedOrder.tradeStartPrice}
            name="tradeStartPrice"
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Price Close</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Price Closed"
            aria-label=""
            aria-describedby=""
            value={updatedOrder.tradeEndPrice}
            name="tradeEndPrice"
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Volume</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Volume"
            aria-label=""
            aria-describedby=""
            name="position"
            value={updatedOrder.position}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Profit</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Profit"
            aria-label=""
            aria-describedby=""
            value={updatedOrder.exitPrice}
            name="exitPrice"
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Time Opened</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Time Opened"
            aria-label=""
            aria-describedby=""
            value={updatedOrder.timeOpened}
            name="timeOpened"
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Time Closed</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Time Closed"
            aria-label=""
            aria-describedby=""
            value={updatedOrder.closedAt}
            name="closedAt"
            onChange={handleChange}
          />
        </div>
      </div>
      <div>
        <button type="button" className="btn btn-default me-2" onClick={updateOrder}>Save</button>
        <button type="button" className="btn btn-danger" onClick={handleClose}>Cancel</button>
      </div>
    </>
    )
  );
}
