/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { MultiSelect } from 'react-multi-select-component';
import DataTable, { createTheme } from 'react-data-table-component';
import 'react-calendar/dist/Calendar.css';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {
  getAffiliate, updateAffiliateToken, editAffiliate, displayAffiliateDeposits,
} from '../../redux/affiliate/affiliateActions';
import {
  getAffiliateRules, addAffiliateRule, editAffiliateRule, updateAffiliateRuleState,
} from '../../redux/affiliateRule/affiliateRuleActions';
import { getSubCRMUsers } from '../../redux/crmUser/crmUserActions';
import {
  showAllUsers, getCountries, editUser, deleteUser,
} from '../../redux/users/userActions';
import { getSalesStatuses } from '../../redux/salesStatus/salesStatusActions';
import { getCurrencyRates } from '../../redux/currencyRate/currencyRateActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';
import { AffiliateClientColumns } from '../../DataTableSchemas/AffiliateClientColumns';
import { AffiliateDepositColumns } from '../../DataTableSchemas/AffiliateDepositColumns';
import { AffiliateRuleColumns } from '../../DataTableSchemas/AffiliateRulesColimns';
import { setScheduleDates, hoursHeader } from './helper';
import { getBrands } from '../../redux/brand/brandActions';

const scheduleDates = setScheduleDates();
const currencyFormatter = require('currency-formatter');

createTheme(
  'solarizedd',
  {
    text: {
      primary: '#fff',
      secondary: '#fff',
    },
    background: {
      default: '#374057',
    },
    context: {
      background: '#374057',
      text: '#FFFFFF',
    },
    divider: {
      default: '#fff',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  },
  'dark',
);

function AffiliateDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [managers, setManagers] = useState([]);
  const [showFtd, setShowFtd] = useState(true);
  const [uploadRole, setUploadRole] = useState(1);
  const [allowRereg, setAllowRereg] = useState(true);
  const [cfdAccountDefault, setCFDAccountDefault] = useState(true);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [defaultManager, setDefaultManager] = useState([]);
  const [affiliateAuthToken, setAffiliateAuthToken] = useState('');
  const [user, setUser] = useState('');
  const [loader, setLoader] = useState(false);
  const [users, setClients] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [isCheckStatus, setIsCheckStatus] = useState([]);
  const [statusesToShow, setStatusesToShow] = useState([]);
  const [filterText] = useState('');
  const [show, setShow] = useState(false);
  const [, setAgentUsers] = useState([]);
  const [, setAssignedTo] = useState(null);
  const [fullname, setFullname] = useState('');
  const [searchEmail, setSearchEmail] = useState('');
  const [statusDropdownShow, setStatusDropdownShow] = useState(false);
  const [, setIsCheckAllStatuses] = useState(false);
  const [statusInput, setStatusInput] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState({
    client: 25,
    deposit: 25,
    routing: 25,
  });
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});

  const paginationClientStorageName = 'DataTable_affiliate/client_pagination';
  const paginationDepositStorageName = 'DataTable_affiliate/deposit_pagination';
  const paginationRoutingStorageName = 'DataTable_affiliate/routing_pagination';
  const filtersStorageName = 'DataTable_affiliate/clients_filters'; 
  const filtersJSON = localStorage.getItem(filtersStorageName);

  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const allClientsList = useSelector((state) => state?.users?.users);
  const totalCount = useSelector((state) => state.users.usersCount);
  const userData = useSelector((state) => state?.affiliate?.affiliate);
  const userEditted = useSelector((state) => state?.affiliate?.affiliateEditted);
  const updatedAffiliateToken = useSelector((state) => state?.affiliate?.updatedAffiliateToken);
  const crmUsers = useSelector((state) => state.crmUser?.crmUsers);
  const allCountries = useSelector((state) => state?.users?.countries);
  const salesStatuses = useSelector((state) => state?.salesStatus?.salesStatuses);
  const affiliateRules = useSelector((state) => state?.affiliateRule?.affiliateRules);
  const affiliateRule = useSelector((state) => state?.affiliateRule?.affiliateRule);
  const affiliateRuleFetched = useSelector((state) => state?.affiliateRule?.affiliateRuleFetched);
  const brands = useSelector((state) => state.brands);

  // Search Boxes
  const [countriesToShow, setCountriesToShow] = useState([]);
  const [, setIsCheckAllCountries] = useState(false);
  const [isCheckCountry, setIsCheckCountry] = useState([]);
  const [assignToShow, setAssignToShow] = useState([]);
  const [countryDropdownShow, setCountryDropdownShow] = useState(false);
  const [countryInput, setCountryInput] = useState('');
  const [, setIsCheckAllAssignTo] = useState(false);
  const [isCheckAssignTo, setIsCheckAssignTo] = useState([]);
  const [assignToDropdownShow, setAssignToDropdownShow] = useState(false);
  const [assignToInput, setAssignToInput] = useState('');

  // Rules
  const [ruleName, setRuleName] = useState('Country');
  const [ruleCondition, setRuleCondition] = useState(true);
  const [ruleValue, setRuleValue] = useState('');
  const [ruleAssignedTo, setRuleAssignedTo] = useState(null);
  const [editRuleId, setEditRuleId] = useState('');
  const [editRuleName, setEditRuleName] = useState('');
  const [editRuleCondition, setEditRuleCondition] = useState(true);
  const [editRuleValue, setEditRuleValue] = useState('');
  const [editRuleAssignedTo, setEditRuleAssignedTo] = useState(null);
  const [showEditRule, setShowEditRule] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [dates, setDates] = useState(scheduleDates);

  const {
    register, handleSubmit, control, reset, formState: { errors },
  } = useForm();
  const {
    register: register1, handleSubmit: handleSubmit1, control: control1, reset: reset1, formState: { errors: errors1 },
  } = useForm();
  const {
    register: register2, handleSubmit: handleSubmit2, control: control2, reset: reset2, formState: { errors: errors2 },
  } = useForm();

  const selectHour = (day, hour) => {
    setDates({
      ...dates,
      [day]: dates[day].map((item) => {
        if (item.label === hour) {
          return { ...item, selected: !item.selected };
        }

        return item;
      }),
    });
  };

  const selectDay = (day) => {
    setDates({
      ...dates,
      [day]: dates[day].map((item) => ({ ...item, selected: !item.selected })),
    });
  };

  const selectHoursInDays = (hour) => {
    const newDates = {};

    Object.keys(dates).forEach((day) => {
      newDates[day] = dates[day].map((item) => {
        if (item.label === hour) {
          return { ...item, selected: !item.selected };
        }

        return item;
      });
    });

    setDates(newDates);
  };

  const selectedDays = useMemo(() => {
    const selected = [];

    Object.keys(dates).forEach((day) => {
      dates[day].forEach((item) => {
        if (item.selected) selected.push(item.utc);
      });
    });

    return selected;
  }, [dates]);

  useEffect(() => {
    if (userData && userData.nonWorkingHours && userData.nonWorkingHours.length) {
      const newDates = structuredClone(scheduleDates);
      userData.nonWorkingHours.forEach((time) => {
        Object.values(newDates).forEach((hours) => {
          hours.forEach((item, index) => {
            if (item.utc === time) newDates[item.weekday][index].selected = true; 
          });
        });
      });

      setDates(newDates);
    }
  }, [userData]);

  const isFiltersEmpty = (filters) => {
    const isEmpty = Object.keys(filters).every((key) => {
      if (filters[key].length) return false;

      return true;
    });

    return isEmpty;
  };

  const setStoredFilterData = async (roleId, uid, clientType) => {
    if (filtersJSON && !isFiltersEmpty(JSON.parse(filtersJSON))) {
      const filters = JSON.parse(filtersJSON);
      setFilters(filters);

      setFullname(filters.fullName || '');
      setSearchEmail(filters.email || '');
      setIsCheckCountry(filters.country || []);
      setIsCheckStatus(filters.status || []);
      setIsCheckAssignTo(filters['assigned to'] || []);
    } else {
      await dispatch(showAllUsers('0', roleId, uid, '', id));
    }
  };

  const handleClear = () => {
    setSearchEmail('');
    setFullname('');
    setIsCheckAssignTo([]);
    setIsCheckCountry([]);
    setIsCheckStatus([]);
    setFilters({
      email: '',
      fullName: '',
    });
    localStorage.removeItem(filtersStorageName);
  };

  const setStoredPagination = () => {
    const clientRowsJSON = localStorage.getItem(paginationClientStorageName);
    const depositRowsJSON = localStorage.getItem(paginationDepositStorageName);
    const routingRowsJSON = localStorage.getItem(paginationRoutingStorageName);

    if (clientRowsJSON) {
      const filterRows = JSON.parse(clientRowsJSON);
      setRowsPerPage((prevRowsPerPage) => ({
        ...prevRowsPerPage,
        client: filterRows.limit || 25,
      }));
    }
    if (depositRowsJSON) {
      const filterRows = JSON.parse(depositRowsJSON);
      setRowsPerPage((prevRowsPerPage) => ({
        ...prevRowsPerPage,
        deposit: filterRows.limit || 25,
      }));
    }
    if (routingRowsJSON) {
      const filterRows = JSON.parse(routingRowsJSON);
      setRowsPerPage((prevRowsPerPage) => ({
        ...prevRowsPerPage,
        routing: filterRows.limit || 25,
      }));
    }
    
    setIsPaginationDT(true);
  };

  const getUsers = (page, rowsPerPage, filters) => {
    const loginData = localStorage.getItem('user');
    const data = JSON.parse(loginData);
    const roleId = data?.roleId;
    const uid = data?._id;

    return dispatch(showAllUsers('0', roleId, uid, '', id, page, rowsPerPage, filters));
  };

  const handleClientRowsPerPageChange = async (currentRowsPerPage, page) => {
    setRowsPerPage((prevRowsPerPage) => ({
      ...prevRowsPerPage,
      client: currentRowsPerPage,
    }));
    localStorage.setItem(paginationClientStorageName, JSON.stringify({ limit: currentRowsPerPage }));
    setTableLoading(true);
    await getUsers(page, currentRowsPerPage, filters);
    setTableLoading(false);
  };

  const handlePageChange = async (page) => {
    setPage(page);
    setTableLoading(true);
    await getUsers(page, rowsPerPage.client, filters);
    setTableLoading(false);
  };

  const handleDepositRowsPerPageChange = async (currentRowsPerPage) => {
    setRowsPerPage((prevRowsPerPage) => ({
      ...prevRowsPerPage,
      deposit: currentRowsPerPage,
    }));
    localStorage.setItem(paginationDepositStorageName, JSON.stringify({ limit: currentRowsPerPage }));
  };

  const handleRoutingRowsPerPageChange = async (currentRowsPerPage) => {
    setRowsPerPage((prevRowsPerPage) => ({
      ...prevRowsPerPage,
      routing: currentRowsPerPage,
    }));
    localStorage.setItem(paginationRoutingStorageName, JSON.stringify({ limit: currentRowsPerPage }));
  };

  useEffect(() => {
    const fetchData = async () => {
      const loginData = localStorage.getItem('user');
      const data = JSON.parse(loginData);
      const roleId = data?.roleId;
      const uid = data?._id;

      await Promise.allSettled([
        dispatch(getSubCRMUsers(roleId, uid)),
        dispatch(getAffiliate(id)),
        dispatch(getCountries()),
        dispatch(getSalesStatuses()),
        dispatch(getAffiliateRules(id)),
        dispatch(getBrands()),
      ]);

      setStoredFilterData(roleId, uid, '');
    };
    fetchData();
    setStoredPagination();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (crmUsers?.length > 0) {
        const allManagers = await crmUsers?.map((manage) => ({ label: `${manage.firstName} ${manage.lastName}`, value: manage._id }));
        setManagers(allManagers);
        setRuleAssignedTo(allManagers?.[0]);

        if (userData?.defaultManagers) {
          const tempCurr = [];
          userData?.defaultManagers.forEach((item) => {
            tempCurr.push({
              value: item._id,
              label: `${item.firstName} ${item.lastName}`,
            });
          });
          setDefaultManager(tempCurr);
        }
      }
    };
    fetchData();
  }, [crmUsers, userData]);

  useEffect(() => {
    if (!brands.length) return;

    setSelectedBrand(brands[0]._id);
  }, [brands]);

  useEffect(() => {
    setLoader(true);

    if (userData) {
      setUser(userData);
      setShowFtd(userData?.showFtd);
      setUploadRole(userData?.uploadRole);
      setAllowRereg(userData?.allowRereg);
      setAffiliateAuthToken(userData?.affialiateToken);

      reset({
        firstname: userData?.firstName,
        lastname: userData?.lastName,
        limitDaily: userData?.limitDaily,
        minFtd: userData?.minFtd ? userData?.minFtd : 0,
      });
      setLoader(false);
    }
  }, [userData]);

  useEffect(() => {
    if (updatedAffiliateToken) {
      setAffiliateAuthToken(updatedAffiliateToken);
    }
  }, [updatedAffiliateToken]);

  const editUserData = {
    firstname: {
      required: 'First name is required',
    },
    lastname: {
      required: 'Last name is required',
    },
    phone: {
      required: 'Phone Number is required',
      pattern: {
        value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
        message: 'Please enter a valid contact number',
      },
    },
  };

  const handleSave = async (formData) => {
    setLoader(true);

    const tempIds = [];
    defaultManager.forEach((item) => {
      tempIds.push(item.value);
    });

    const data = {
      firstName: formData.firstname,
      lastName: formData.lastname,
      limitDaily: formData.limitDaily ? formData.limitDaily : 0,
      minFtd: formData.minFtd ? formData.minFtd : 0,
      showFtd,
      uploadRole,
      allowRereg,
      defaultManager: tempIds,
    };

    await dispatch(editAffiliate(id, data));
  };

  useEffect(() => {
    if (userEditted) {
      setLoader(false);
    }
  }, [userEditted]);

  const HandleGenerateNewToken = async () => {
    await dispatch(updateAffiliateToken(id));
  };

  const onTokenChangeHandler = async () => {
  };

  const colourStyles = {
    control: (styles, { isDisabled, isSelected }) => ({
      ...styles,
      background: isDisabled ? '#aaa' : '#374057',
      color: isDisabled ? '#aaa' : 'fff',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      border: '1px solid #374057',
      boxShadow: isSelected ? 'none' : 'none',
      borderColor: isSelected ? '#374057' : '#374057',
      '&:hover': {
        boxShadow: 'none',
      },
    }),
    input: (styles) => ({
      ...styles,
      color: '#fff',
    }),
    singleValue: (styles) => ({
      ...styles,
      color: '#fff',
    }),
    menuList: (styles) => ({
      ...styles,
      background: '#374057',
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? '#16202e'
        : isSelected
          ? '#16202e'
          : undefined,
      color: 'fff',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      zIndex: 1,
      '&:hover': {
        background: isDisabled ? '#aaa' : '#16202e',
      },
    }),
  };

  useEffect(() => {
    if (allCountries?.length) {
      setCountriesToShow(allCountries);
    }
  }, [allCountries]);

  useEffect(() => {
    if (salesStatuses?.length) {
      setStatusesToShow(salesStatuses);
    }
  }, [salesStatuses]);

  useEffect(async () => {
    if (filters && Object.keys(filters).length) {
      localStorage.setItem(filtersStorageName, JSON.stringify(filters));
      setPage(1);
      setTableLoading(true);
      await getUsers(1, rowsPerPage.client, filters);
      setTableLoading(false);
    }
  }, [filters]);

  // Client Tabs
  const searchByName = ({ target: { value } }) => {
    setFullname(value);

    const delayDebounceFn = setTimeout(() => {
      if (value.length >= 3 || !value.length) setFilters({ ...filters, fullName: value });
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  };

  const searchByEmail = ({ target: { value } }) => {
    setSearchEmail(value);

    const delayDebounceFn = setTimeout(() => {
      if (value.length >= 3 || !value.length) setFilters({ ...filters, email: value });
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  };

  const handleStatusChange2 = (_id, e) => {
    if (_id) {
      const newUsers = [...users];
      const objIndex = newUsers.findIndex(((obj) => obj?.users?._id === _id));
      newUsers[objIndex].users.salesStatusId = e.target.value;
      setClients([...newUsers]);
      const data = { salesStatusId: e.target.value ? e.target.value : null };
      dispatch(editUser(_id, data, false));
    }
  };

  const handleCountryDropdownShow = () => {
    setCountryDropdownShow(!countryDropdownShow);
    setStatusDropdownShow(false);
    setAssignToDropdownShow(false);
  };

  const handleStatusDropdownShow = () => {
    setStatusDropdownShow(!statusDropdownShow);
    setCountryDropdownShow(false);
    setAssignToDropdownShow(false);
  };

  const handleAssignToDropdownShow = () => {
    setAssignToDropdownShow(!assignToDropdownShow);
    setStatusDropdownShow(false);
    setCountryDropdownShow(false);
  };

  const myCountryHandler = async (e) => {
    setCountryInput(e.target.value);
    const countryItems = await allCountries?.filter(
      (item) => item?.nicename && item?.nicename.toLowerCase().includes(e.target.value.toLowerCase()),
    );
    setCountriesToShow(countryItems);
  };

  const myStatustHandler = async (e) => {
    setStatusInput(e.target.value);
    const statusItems = await salesStatuses?.filter(
      (item) => item?.name && item?.name.toLowerCase().includes(e.target.value.toLowerCase()),
    );
    setStatusesToShow(statusItems);
  };

  const assignToHandler = async (e) => {
    const val = e.target.value;
    setAssignToInput(val);

    const assignToItems = await crmUsers?.filter(
      (item) => item?.firstName?.toLowerCase().includes(val.toLowerCase())
            || item?.lastName?.toLowerCase().includes(val.toLowerCase()),
    );
    setAssignToShow(assignToItems);
  };

  const handleSelectAllCountries = (e, allChecked) => {
    setIsCheckAllCountries(allChecked);
    setIsCheckCountry(allCountries?.map((li) => li._id));
    if (!allChecked) {
      setIsCheckCountry([]);
    }
  };

  const handleCountryClick = (e) => {
    const { value, checked } = e.target;
    setIsCheckCountry([...isCheckCountry, value]);
    if (!checked) {
      setIsCheckCountry(isCheckCountry.filter((item) => item !== value));
    }
  };

  const handleSelectAllStatuses = (e, allChecked) => {
    setIsCheckAllStatuses(allChecked);
    setIsCheckStatus(salesStatuses?.map((li) => li._id));
    if (!allChecked) {
      setIsCheckStatus([]);
    }
  };

  const handleStatusClick = (e) => {
    const { value, checked } = e.target;
    setIsCheckStatus([...isCheckStatus, value]);
    if (!checked) {
      setIsCheckStatus(isCheckStatus.filter((item) => item !== value));
    }
  };

  const handleSelectAllAssignTo = (e, allChecked) => {
    setIsCheckAllAssignTo(allChecked);
    setIsCheckAssignTo(crmUsers?.map((li) => li._id));
    if (!allChecked) {
      setIsCheckAssignTo([]);
    }
  };

  const handleAssignToClick = (e) => {
    const { value, checked } = e.target;
    setIsCheckAssignTo([...isCheckAssignTo, value]);
    if (!checked) {
      setIsCheckAssignTo(isCheckAssignTo.filter((item) => item !== value));
    }
  };

  const userAction = (id, type) => {
    Swal.fire({
      title: `Are you sure you want to ${type && type === 'block' ? 'block' : 'unblock'}?`,
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `${type && type === 'block' ? 'Block' : 'Unblock'}`,
    }).then((result) => {
      if (result.isConfirmed) {
        const data = { status: !(type && type === 'block') };
        dispatch(editUser(id, data));
      }
    });
  };

  // eslint-disable-next-line no-shadow
  const deleteAction = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed === true) {
        const userIdData = localStorage.getItem('userId');
        const loginUserId = JSON.parse(userIdData);

        const data = {
          crmUserId: loginUserId,
        };
        dispatch(deleteUser(id, data));
      }
    });
  };

  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  const timePassed = (date) => {
    let status = '';
    if (date) {
      const first = new Date(date);
      const end = new Date(Date.now());
      const elapsed = (end - first);
      const CalcElapsed = elapsed / 1000;
      if (CalcElapsed > 0 && CalcElapsed < 50) {
        status = 'Online';
      } else {
        status = 'Offline';
      }
      return status;
    }
    return status;
  };

  const padTo2Digits = (num) => num.toString().padStart(2, '0');

  const formatDate = (date) => (
    `${[
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-')
    } ${
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(':')}`
  );

  const titleCase = (string) => string[0].toUpperCase() + string.slice(1).toLowerCase();

  const handleGlobalFilter = () => {
    const filteredItemsResult = users?.filter(
      (item) => (item.users.firstName && item.users.firstName.toLowerCase().includes(filterText.toLowerCase()))
            || (item?.users?.phone && item?.users?.phone.toLowerCase().includes(filterText.toLowerCase()))
            || (item.users.email && item.users.email.toLowerCase().includes(filterText.toLowerCase()))
            || (item.userType && item.userType.toLowerCase().includes(filterText.toLowerCase()))
            || (item?.users?.country?.[0]?.nicename && item?.users?.country?.[0]?.nicename.toLowerCase().includes(filterText.toLowerCase()))
            || (item?.users?.account?.previousTotalAmount && currencyFormatter.format(item?.users?.account?.previousTotalAmount, { code: 'USD' }).toLowerCase().includes(filterText.toLowerCase()))
            || (item?.users?.createdAt && formatDate(new Date(item?.users?.createdAt)).toLowerCase().includes(filterText.toLowerCase()))
            || (item?.users?.lastLoginAt && formatDate(new Date(item?.users?.lastLoginAt))?.toLowerCase().includes(filterText.toLowerCase()))
            // item?.users?.clientStatus && userTypeStatus?.find(stat => stat.value == item?.users?.clientStatus)?.label?.toLowerCase().includes(filterText.toLowerCase()) ||
            // !item?.users?.clientStatus && userTypeStatus?.[0]?.label?.toLowerCase().includes(filterText.toLowerCase()) ||
            || (item?.users?.salesStatusId && salesStatuses?.find((stat) => stat._id === item?.users?.salesStatusId)?.name?.toLowerCase().includes(filterText.toLowerCase()))
            || (!item?.users?.salesStatusId && 'Select Status'.toLowerCase().includes(filterText.toLowerCase()))
            || (item?.users?.assignedToAgent?.[0] && item?.users?.assignedToAgent?.[0]?.firstName?.toLowerCase().includes(filterText.toLowerCase()))
            || (item?.users?.assignedToAgent?.[0] && item?.users?.assignedToAgent?.[0]?.lastName?.toLowerCase().includes(filterText.toLowerCase())),
    );
    setFilteredItems([...filteredItemsResult]);
  };

  const handleMultiSelectFilter = () => {
    const filteredItemsResult = users?.filter(
      (item) => {
        const isCheckCountryLength = isCheckCountry?.length;
        const isCheckStatusLength = isCheckStatus?.length;
        const isCheckAssignToLength = isCheckAssignTo?.length;
        const isCountryInclude = isCheckCountry?.includes(item?.users?.country?.[0]?._id);
        const isStatusInclude = isCheckStatus?.includes(item?.users?.salesStatusId);
        const isAssignToInclude = isCheckAssignTo?.includes(item?.users?.assignedToAgent?.[0]?._id);

        if (isCheckCountryLength > 0 && isCheckStatusLength > 0 && isCheckAssignToLength > 0) {
          return isCountryInclude && isStatusInclude && isAssignToInclude;
        } if (isCheckCountryLength > 0 && isCheckStatusLength > 0 && isCheckAssignToLength < 1) {
          return isCountryInclude && isStatusInclude;
        } if (isCheckCountryLength > 0 && isCheckStatusLength < 1 && isCheckAssignToLength > 0) {
          return isCountryInclude && isAssignToInclude;
        } if (isCheckCountryLength < 1 && isCheckStatusLength > 0 && isCheckAssignToLength > 0) {
          return isStatusInclude && isAssignToInclude;
        } if (isCheckCountryLength > 0 && isCheckStatusLength < 1 && isCheckAssignToLength < 1) {
          return isCountryInclude;
        } if (isCheckCountryLength < 1 && isCheckStatusLength > 0 && isCheckAssignToLength < 1) {
          return isStatusInclude;
        } if (isCheckCountryLength < 1 && isCheckStatusLength < 1 && isCheckAssignToLength > 0) {
          return isAssignToInclude;
        }
        return true;
      },

    );
    setFilteredItems([...filteredItemsResult]);
  };

  useEffect(() => {
    handleGlobalFilter();
  }, [filterText]);

  useEffect(() => {
    handleMultiSelectFilter();
  }, [isCheckCountry, isCheckStatus, isCheckAssignTo]);

  useEffect(() => {
    if (crmUsers?.length > 0) {
      const allAgents = crmUsers?.map((agen) => ({ label: `${agen.firstName} ${agen.lastName}`, value: agen._id }));
      setAgentUsers(allAgents);
      setAssignToShow(crmUsers);
      setAssignedTo({ label: allAgents?.[0]?.label, value: allAgents?.[0]?.value });
    }
  }, [crmUsers]);

  /* Deposits Tab */
  const affiliatedDeposits = useSelector((state) => state?.affiliate?.affiliatedDeposits);

  const [depositFilterText, setDepositFilterText] = React.useState('');
  const [resetDepositPaginationToggle, setResetDepositPaginationToggle] = React.useState(false);
  const [allTransactions, setAllTransactions] = useState([]);

  const subHeaderComponentMemo = React.useMemo(() => {
    // eslint-disable-next-line no-unused-vars
    const handleClear = () => {
      if (depositFilterText) {
        setResetDepositPaginationToggle(!resetDepositPaginationToggle);
        setDepositFilterText('');
      }
    };
  }, [depositFilterText, resetDepositPaginationToggle]);

  useEffect(() => {
    setLoader(true);
    const { roleId, _id: uid } = JSON.parse(localStorage.getItem('user'));

    Promise.allSettled([
      dispatch(getCurrencyRates('USD')),
      dispatch(displayAffiliateDeposits(roleId, uid, id)),
    ]);
  }, []);

  useEffect(() => {
    if (affiliatedDeposits?.length > 0) {
      setAllTransactions(affiliatedDeposits);
      setLoader(false);
    }
  }, [affiliatedDeposits]);

  const sortedTransactions = allTransactions.sort((a, b) => {
    if (a.isResolved === 0 && b.isResolved !== 0) {
      return -1;
    } if (a.isResolved !== 0 && b.isResolved === 0) {
      return 1;
    }
    return new Date(moment(b.createdAt).format('YYYY-MM-DD HH:mm:ss')) - new Date(moment(a.createdAt).format('YYYY-MM-DD HH:mm:ss'));
  });

  // Rules

  const handleRuleAssignedToChange = (selectedRuleAssignedTo) => {
    setRuleAssignedTo(selectedRuleAssignedTo);
  };

  const handleAffiliateRuleSave = () => {
    const data = {
      affiliateId: id,
      assignedTo: ruleAssignedTo?.value,
      key: ruleName,
      isEqual: ruleCondition,
      value: ruleValue,
    };

    handleClose();
    dispatch(addAffiliateRule(data));
  };

  const handleEditRuleAssignedToChange = (selectedEditRuleAssignedTo) => {
    setEditRuleAssignedTo(selectedEditRuleAssignedTo);
  };

  const handleEditRuleClose = () => {
    setShowEditRule(false);
    setEditRuleId('');
    setEditRuleName('');
    setEditRuleCondition(true);
    setEditRuleValue('');
    setEditRuleAssignedTo(null);
  };

  const handleEditRuleShow = () => {
    const assignedToManager = managers.find((stat) => stat.value === affiliateRule?.assignedTo);

    setShowEditRule(true);
    setEditRuleId(affiliateRule._id);
    setEditRuleName(affiliateRule.key);
    setEditRuleCondition(affiliateRule.isEqual);
    setEditRuleValue(affiliateRule.value);
    setEditRuleAssignedTo(assignedToManager);
  };

  useEffect(() => {
    if (affiliateRuleFetched && Object.keys(affiliateRule)?.length > 0) {
      dispatch(updateAffiliateRuleState());
      handleEditRuleShow();
    }
  }, [affiliateRuleFetched, affiliateRule]);

  const handleEditAffiliateRule = () => {
    const data = {
      affiliateId: id,
      assignedTo: editRuleAssignedTo?.value,
      key: editRuleName,
      isEqual: editRuleCondition,
      value: editRuleValue,
    };

    if (editRuleId) {
      dispatch(editAffiliateRule(editRuleId, data));
    }

    handleEditRuleClose();
  };

  const affClientColumns = AffiliateClientColumns(
    fullname,
    searchByName,
    searchEmail,
    searchByEmail,
    permissionName,
    copyReferral,
    handleCountryDropdownShow,
    countryDropdownShow,
    countryInput,
    myCountryHandler,
    handleSelectAllCountries,
    countriesToShow,
    isCheckCountry,
    handleCountryClick,
    titleCase,
    handleStatusDropdownShow,
    statusDropdownShow,
    statusInput,
    myStatustHandler,
    handleSelectAllStatuses,
    statusesToShow,
    isCheckStatus,
    handleStatusClick,
    salesStatuses,
    handleStatusChange2,
    handleAssignToDropdownShow,
    assignToDropdownShow,
    assignToInput,
    assignToHandler,
    handleSelectAllAssignTo,
    assignToShow,
    isCheckAssignTo,
    handleAssignToClick,
    formatDate,
    timePassed,
    deleteAction,
    userAction,
    filters,
    setFilters,
    setIsCheckCountry,
    setIsCheckStatus,
    setIsCheckAssignTo,
  );
  const affDepositColumns = AffiliateDepositColumns();
  const affRuleColumns = AffiliateRuleColumns();

  return (
    loader ? <FullPageTransparentLoader />
      : (
        <>
          <div className="content-wrapper right-content-wrapper crm-tabs-holder">
            <div className="content-box bitrex-wrapper">
              <div className="bitrex-wrapper-content">
                <h2>{`${user?.firstName?.toUpperCase()} ${user?.lastName?.toUpperCase()}`}</h2>
              </div>
              <ul className="nav nav-tabs mb-3" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active  text-capitalize" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">personal</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link  text-capitalize" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">clients</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link  text-capitalize" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">deposits</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link  text-capitalize" id="contact-three-tab" data-bs-toggle="tab" data-bs-target="#contact-three" type="button" role="tab" aria-controls="contact-three" aria-selected="false">routing</button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active " id="home" role="tabpanel" aria-labelledby="home-tab">
                  <div className="row">
                    <div className="col-xl-6 col-lg-12">
                      <div className="bitrex-box-bg tab-main-box mb-4">
                        <div className="bitrex-box-bg-content">
                          <h2>AFFILIATE INFORMATION</h2>
                        </div>
                        <form className="bitrex-form form-group" onSubmit={handleSubmit(handleSave)}>
                          <div className="input-wrapper d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-between">
                            <label htmlFor="exampleFormControlInput1" className="form-label">First Name</label>
                            {/* <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Bitrex" /> */}
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter First name"
                              {...register('firstname', editUserData?.firstname)}
                              name="firstname"
                              defaultValue={user?.firstName}
                              control={control}
                            />
                            {errors?.firstname && <span className="errMsg">{errors?.firstname?.message}</span>}
                          </div>
                          <div className="input-wrapper d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-between">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Last Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Last name"
                              {...register('lastname', editUserData?.lastname)}
                              name="lastname"
                              defaultValue={user?.lastName}
                              control={control}
                            />
                            {errors?.lastname && <span className="errMsg">{errors?.lastname?.message}</span>}
                          </div>
                          <div className="input-wrapper d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-between">
                            <label htmlFor="exampleFormControlInput1" className="form-label">E-mail</label>
                            <input
                              type="email"
                              placeholder="email"
                              className="form-control"
                              value={user?.email}
                              disabled
                            />
                          </div>
                          <div className="input-wrapper custom-milti-select">
                            <label className="form-label">Default Managers</label>
                            <MultiSelect options={managers} value={defaultManager} onChange={setDefaultManager} labelledBy="Select" />
                          </div>
                          <div className="input-wrapper d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-between">
                            <label className="control-label">FTD data in API</label>
                            <select className="form-control user-status-select" name="showFtd" value={showFtd} onChange={(e) => setShowFtd(e.target.value)}>
                              <option value>Show</option>
                              <option value={false}>Hide</option>
                            </select>
                          </div>
                          <div className="input-wrapper d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-between">
                            <label className="control-label">Role of uploaded users</label>
                            <select className="form-control user-status-select" name="uploadRole" value={uploadRole} onChange={(e) => setUploadRole(e.target.value)}>
                              <option value={1}>Lead</option>
                              <option value={2}>Client</option>
                            </select>
                          </div>
                          {/* <div className="input-wrapper d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-between">
                                          <label htmlFor="exampleFormControlInput1" className="form-label">Role of uploaded users</label>
                                          <Select
                                             className='form-control'
                                             value={selectedUserTypeThree}
                                             onChange={handleUserTypeChange}
                                             options={userTypeOptionsThree}
                                             styles={colourStyles}
                                          />
                                       </div> */}
                          <div className="input-wrapper d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-between">
                            <label className="form-label">Daily Limit</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter Daily Limit"
                              {...register('limitDaily', editUserData?.limitDaily)}
                              name="limitDaily"
                              defaultValue={user?.limitDaily}
                              control={control}
                            />
                            {errors?.limitDaily && <span className="errMsg">{errors?.limitDaily?.message}</span>}
                          </div>

                          <div className="input-wrapper d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-between">
                            <label className="form-label">Uploaded today</label>
                            <input
                              type="number"
                              placeholder="Uploaded today"
                              className="form-control"
                              value={user?.uploadedToday}
                              disabled
                            />
                          </div>

                          <div className="input-wrapper d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-between">
                            <label className="form-label">Minimum FTD Amount</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter Minimum FTD Amount"
                              {...register('minFtd', editUserData?.minFtd)}
                              name="minFtd"
                              defaultValue={user?.minFtd}
                              control={control}
                            />
                            {errors?.minFtd && <span className="errMsg">{errors?.minFtd?.message}</span>}
                          </div>

                          <div className="input-wrapper d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-between">
                            <label className="control-label">Allow Re-registeration</label>
                            <select className="form-control user-status-select" name="allowRereg" value={allowRereg} onChange={(e) => setAllowRereg(e.target.value)}>
                              <option value>Yes</option>
                              <option value={false}>No</option>
                            </select>
                          </div>

                          <div className="input-wrapper d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-between">
                            <label className="control-label">Create CFD Account by Default</label>
                            <select className="form-control user-status-select" name="cfdAccountDefault" value={cfdAccountDefault} onChange={(e) => setCFDAccountDefault(e.target.value)}>
                              <option value>Yes</option>
                              <option value={false}>No</option>
                            </select>
                          </div>

                          <div className="d-flex justify-content-end">
                            <button className="btn-default" type="submit">Update Affiliate Details</button>
                          </div>
                        </form>
                      </div>
                      <div className="bitrex-box-bg tab-main-box mb-4">
                        <div className="bitrex-box-bg-content">
                          <h2>API & REFERRAL</h2>
                        </div>
                        <form className="bitrex-form form-group">
                          <div className="input-wrapper d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-between">
                            <label className="control-label">Brand</label>
                            <select className="form-control user-status-select" name="affBrand" value={selectedBrand} onChange={(e) => setSelectedBrand(e.target.value)}>
                              {
                                brands.map((brand) => (
                                  <option key={brand._id} value={brand._id}>{brand.name}</option>
                                ))
                              }
                            </select>
                          </div>
                          <div className="input-wrapper d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-between">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Endpoint</label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" value={`${process.env.REACT_APP_SERVER_URL}/api/affiliate/leads/${selectedBrand}`} placeholder={`${process.env.REACT_APP_SERVER_URL}/api/affiliate/leads/${selectedBrand}`} />
                          </div>
                          <div className="input-wrapper d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-between">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Authorization Token</label>
                            <textarea rows="5" className="form-control" value={affiliateAuthToken} onChange={onTokenChangeHandler} />
                          </div>
                        </form>
                        <div className="d-flex justify-content-end">
                          <button type="button" className="btn-default" onClick={() => HandleGenerateNewToken()}>Generate New Token</button>
                        </div>
                      </div>
                      <div className="bitrex-box-bg tab-main-box schedule-box mb-4">
                        <div className="bitrex-box-bg-content">
                          <div className="head-wrap d-flex align-items-center justify-content-between mb-5">
                            <h2 className="text-uppercase mb-0">Schedule</h2>
                          </div>
                          <p>Click cell, day or hour. The table is in your local time, will be converted to UTC automatically.</p>
                        </div>
                        <div className="tbl-wrapper">
                          <div className="table-responsive">
                            <table className="working-time">
                              <tr>
                                <td />
                                {
                                  hoursHeader.map((item) => (
                                    <th key={item} className="hour pointer" onClick={() => selectHoursInDays(item)}>{item}</th>
                                  ))
                                }
                              </tr>
                              {
                                  Object.keys(dates).map((day) => (
                                    <tr key={day}>
                                      <td className="day pointer" onClick={() => selectDay(day)}>{day}</td>
                                      {
                                          dates[day].map(({ label, selected }) => (
                                            <td key={label} className={`cell pointer ${selected ? '' : 'activated'}`} data-checked="1" onClick={() => selectHour(day, label)} />
                                          ))
                                        }
                                    </tr>
                                  ))
                                }
                            </table>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          <button type="button" className="btn-default" onClick={() => dispatch(editAffiliate(id, { nonWorkingHours: selectedDays }))}>Update Schedule</button>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12">
                      <div className="bitrex-box-bg tab-main-box mb-4">
                        <div className="bitrex-box-bg-content">
                          <h2>API:UPLOAD LEADS (POST)</h2>
                        </div>
                        <div className="bitrex-box-bg-info">
                          <h5>Headers:</h5>
                        </div>
                        <div className="bitrex-box-bg-info bitrex-box-bg-content padding">
                          <h6><span>Authorization:</span></h6>
                          <p>{affiliateAuthToken}</p>
                          <h6>
                            <span>Content-Type: </span>
                            {' '}
                            application/json
                          </h6>
                        </div>
                        <div className="bitrex-box-bg-info">
                          <h5>Body:</h5>
                        </div>
                        <div className="bitrex-box-bg-info padding">
                          <h6>
                            <span>json: </span>
                            {' '}
                            {'{"leads": [{}, {} ... {}]}, see object structure in the example below'}
                          </h6>
                        </div>
                        <div className="bitrex-box-bg-info box-border">
                          <h5>Example:</h5>
                        </div>
                        <div className="dummy-box-code">
                          <pre>
                            {'curl --location --request POST '}
                            <span className="endpoint">
                              {`'${process.env.REACT_APP_SERVER_URL}/api/affiliate/leads/${selectedBrand}'`}
                              {' '}
                              \
                            </span>
                            {`
                                          --header 'Authorization: `}
                            <span className="auth-token">
                              {`${affiliateAuthToken}'`}
                              {' '}
                              \
                            </span>
                            <span>
                              {` 
                                          --header 'Content-Type: application/json' `}
                              {' '}
                              \
                            </span>
                            {'\n'}
                            {`--data-raw '{
                                             "leads": [{
                                             "email": "test@test.com",
                                             "phone": "+77777777777",
                                             "firstName": "Firstname",
                                             "lastName": "Lastname",
                                             "country": "Country Name",
                                             "password": "Password",
                                             "additionalInfo": "Some extra data",
                                             "source": "WebsiteName or Affiliate brandName"
                                             }]
                                          } '`}
                          </pre>
                        </div>
                      </div>
                      <div className="bitrex-box-bg tab-main-box mb-4">
                        <div className="bitrex-box-bg-content">
                          <h2>API:GET LEAD STATUS(GET)</h2>
                        </div>
                        <div className="bitrex-box-bg-info">
                          <h5>Headers:</h5>
                        </div>
                        <div className="bitrex-box-bg-info bitrex-box-bg-content padding">
                          <h6><span>Authorization:</span></h6>
                          <p>{affiliateAuthToken}</p>
                          <h6>
                            <span>Content-Type: </span>
                            {' '}
                            application/json
                          </h6>
                        </div>
                        <div className="bitrex-box-bg-info">
                          <h5>PARAMS:</h5>
                        </div>
                        <div className="bitrex-box-bg-info padding">
                          <h6>
                            <span>deposited: </span>
                            {' '}
                            1 or 0
                          </h6>
                          <h6>
                            <span>from: </span>
                            {' '}
                            unix time in seconds 
                            {' '}
                            <a target="_blank" href="https://www.epochconverter.com/" rel="noreferrer">(converter)</a>
                          </h6>
                          <h6>
                            <span>to: </span>
                            {' '}
                            unix time in seconds 
                            {' '}
                            <a target="_blank" href="https://www.epochconverter.com/" rel="noreferrer">(converter)</a>
                          </h6>
                          <h6>
                            <span>offset: </span>
                            {' '}
                            number of records that need to skip
                          </h6>
                          <h6>
                            <span>limit: </span>
                            {' '}
                            number of records in request
                          </h6>
                        </div>
                        <div className="bitrex-box-bg-info box-border">
                          <h5>Example:</h5>
                        </div>
                        <div className="dummy-box-code">
                          <pre>
                            {'curl --location --request GET '}
                            <span className="endpoint">
                              {`'${process.env.REACT_APP_SERVER_URL}/api/affiliate/leads?deposited=1&from=1583020800&to=1599051426&offset=15&limit=200'`}
                              {' '}
                              \
                              <span>
                                {` 
                                          --header 'Content-Type: application/json' `}
                                {' '}
                                \
                              </span>
                            </span>
                            {`
                                          --header 'Authorization: `}
                            <span className="auth-token">
                              {`${affiliateAuthToken}'`}
                              {' '}
                            </span>
                          </pre>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                  <button
                    style={{ 
                      marginLeft: '4px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                    type="button"
                    className="btn btn-default"
                    onClick={handleClear}
                  >
                    <FontAwesomeIcon icon={faTimes} size="xs" />
                    Clear
                  </button>
                  <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
                    {
                      isPaginationDT
                        && (
                          <DataTable
                            rows="1"
                            columns={affClientColumns}
                            data={allClientsList}
                            pagination
                            paginationServer
                            paginationTotalRows={totalCount}
                            paginationPerPage={rowsPerPage.client}
                            paginationRowsPerPageOptions={[25, 50, 100, 500]}
                            onChangeRowsPerPage={handleClientRowsPerPageChange}
                            onChangePage={handlePageChange}
                            persistTableHead
                            selectableRowsHighlight
                            selectableRowsVisibleOnly
                            progressPending={tableLoading}
                            progressComponent={<div className="datatable-loader__background" />}
                            theme="solarizedd"
                          />
                        ) 
                    }
                  </div>
                </div>
                <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                  <div className="dashboard-tbl-wrapper custom-tbl-wrapper affiliate-tab-tbl mt-3">
                    {
                      isPaginationDT
                        && (
                          <DataTable
                            title="Deposits"
                            columns={affDepositColumns}
                            data={sortedTransactions}
                            pagination
                            paginationResetDefaultPage={resetDepositPaginationToggle} // optionally, a hook to reset pagination to page 1
                            paginationPerPage={rowsPerPage.deposit}
                            paginationRowsPerPageOptions={[25, 50, 100, 500]}
                            onChangeRowsPerPage={handleDepositRowsPerPageChange}
                            subHeaderComponent={subHeaderComponentMemo}
                            persistTableHead
                            highlightOnHover
                            theme="solarizedd"
                            className="specific-field-table custome-table-scroll"
                          />
                        )
                    }
                  </div>
                </div>
                <div className="tab-pane fade" id="contact-three" role="tabpanel" aria-labelledby="contact-three-tab">
                  <button type="submit" onClick={handleShow} className="btn-add-rule">
                    <span className="icon"><FontAwesomeIcon icon={faPlusCircle} className="me-2" /></span>
                    Add Rule
                  </button>
                  <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-0">
                    {
                      isPaginationDT
                        && (
                          <DataTable
                            title="Routing Rules"
                            columns={affRuleColumns}
                            data={affiliateRules}
                            pagination
                            paginationPerPage={rowsPerPage.routing}
                            paginationRowsPerPageOptions={[25, 50, 100, 500]}
                            onChangeRowsPerPage={handleRoutingRowsPerPageChange}
                            persistTableHead
                            highlightOnHover
                            theme="solarizedd"
                            className="specific-field-table custome-table-scroll"
                          />
                        ) 
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal show={show} onHide={handleClose} className="routing-modal">
            <Modal.Header closeButton />
            <div className="rounting-popup">
              <div className="popup-header d-flex flex-row align-items-center">
                <h5 className="text-capitalize mb-3">Add new rule</h5>
              </div>
              <div className="popup-body">
                <form className="bitrex-form form-group mb-3">
                  <div className="form-group mb-2">
                    <label>Rule</label>
                    <select className="form-control" name="ruleName" value={ruleName} onChange={(e) => setRuleName(e.target.value)}>
                      <option value="Country">Country</option>
                      <option value="Affiliate Name">Affiliate Name</option>
                    </select>
                  </div>
                  <div className="form-group mb-2">
                    <label>Condition</label>
                    <select className="form-control" name="ruleCondition" value={ruleCondition} onChange={(e) => setRuleCondition(e.target.value)}>
                      <option value>Is Equal</option>
                      <option value={false}>Not Equal</option>
                    </select>
                  </div>
                  <div className="form-group mb-2">
                    <label>Value</label>
                    <input type="text" name="ruleValue" value={ruleValue} onChange={(e) => setRuleValue(e.target.value)} className="form-control" />
                  </div>
                  <div className="form-group mb-2">
                    <label>Assign to</label>
                    <Select
                      className="form-control"
                      value={ruleAssignedTo}
                      onChange={handleRuleAssignedToChange}
                      options={managers}
                      styles={colourStyles}
                    />
                  </div>
                </form>
                <div className="btn-wrapper d-flex align-items-center justify-content-end">
                  <button type="button" className="btn btn-danger me-2" onClick={handleClose}>Cancel</button>
                  <button type="button" className="btn btn-success" onClick={handleAffiliateRuleSave}>Save</button>
                </div>
              </div>

            </div>
          </Modal>

          <Modal show={showEditRule} onHide={handleEditRuleClose} className="routing-modal">
            <Modal.Header closeButton />
            <div className="rounting-popup">
              <div className="popup-header d-flex flex-row align-items-center">
                <h5 className="text-capitalize mb-3">Edit rule</h5>
              </div>
              <div className="popup-body">
                <form className="bitrex-form form-group mb-3">
                  <div className="form-group mb-2">
                    <label>Rule</label>
                    <select className="form-control" name="editRuleName" value={editRuleName} onChange={(e) => setEditRuleName(e.target.value)}>
                      <option value="Country">Country</option>
                      <option value="Affiliate Name">Affiliate Name</option>
                    </select>
                  </div>
                  <div className="form-group mb-2">
                    <label>Condition</label>
                    <select className="form-control" name="editRuleCondition" value={editRuleCondition} onChange={(e) => setEditRuleCondition(e.target.value)}>
                      <option value>Is Equal</option>
                      <option value={false}>Not Equal</option>
                    </select>
                  </div>
                  <div className="form-group mb-2">
                    <label>Value</label>
                    <input type="text" name="editRuleValue" value={editRuleValue} onChange={(e) => setEditRuleValue(e.target.value)} className="form-control" />
                  </div>
                  <div className="form-group mb-2">
                    <label>Assign to</label>
                    <Select
                      className="form-control"
                      value={editRuleAssignedTo}
                      onChange={handleEditRuleAssignedToChange}
                      options={managers}
                      styles={colourStyles}
                    />
                  </div>
                </form>
                <div className="btn-wrapper d-flex align-items-center justify-content-end">
                  <button type="button" className="btn btn-danger me-2" onClick={handleEditRuleClose}>Cancel</button>
                  <button type="button" className="btn btn-success" onClick={handleEditAffiliateRule}>Save</button>
                </div>
              </div>

            </div>
          </Modal>
        </>
      )
  );
}

export default AffiliateDetails;
