/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faPencil } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import CreateAccountModal from './CreateAccountModal';
import CreateSavingAccountModal from './CreateSavingAccountModal';
import EditAccountModal from './EditAccountModal';
import { deleteSavingAccount, deleteUserAccount, getCfdAccountsByQuery } from '../../../../redux/cfdAccount/cfdAccountActions';
import { ModalContext } from '../../../../context';
import { toFixed } from '../../../../helpers/utils';


function Accounts({ userId }) {
  const dispatch = useDispatch();
  const { showModal, hideModal } = useContext(ModalContext);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tableLoading, setTableLoading] = useState(false);

  const { cfdAccounts, totalCount } = useSelector((state) => state.cfdAccounts?.allCfdAccounts);

  useEffect(async () => {
    setTableLoading(true);

    await dispatch(getCfdAccountsByQuery({ page, limit: rowsPerPage, query: { userId, isSaving: JSON.stringify([false, true]) } }));

    setTableLoading(false);
  }, [page, rowsPerPage, userId]);

  const deleteAccount = (id, isSaving = false) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    })
      .then((result) => {
        if (result.isConfirmed) {
          if (isSaving) {
            dispatch(deleteSavingAccount(id));
          } else {
            dispatch(deleteUserAccount(id));
          }
        }
      });
  };

  const columns = [
    {
      name: 'ID',
      minWidth: '150px',
      selector: ({ customId }) => customId, 
    },
    {
      name: 'Type',
      minWidth: '150px',
      selector: ({ type, isSaving }) => (!isSaving ? type.name : '-'),
    },
    {
      name: 'Product',
      minWidth: '150px',
      selector: ({ isSaving }) => (isSaving ? 'Saving' : 'CFD'),
    },
    {
      name: 'Currency',
      minWidth: '150px',
      selector: ({ cfdAccountCurrency, currency }) => cfdAccountCurrency?.symbol || currency?.symbol || '-', 
      sortable: true,
    },
    {
      name: 'Balance',
      minWidth: '150px',
      selector: ({ balance }) => toFixed(balance, 2), 
      sortable: true,
    },
    {
      name: 'Credit',
      minWidth: '150px',
      selector: ({ credit }) => (credit ? toFixed(credit, 2) : '-'), 
      sortable: true,
    },
    {
      name: 'Leverage',
      minWidth: '180px',
      selector: ({ leverage }) => (leverage || '-'), 
      sortable: true,
    },
    {
      name: 'Lot Step',
      minWidth: '180px',
      selector: ({ lotStep }) => (lotStep || '-'), 
      sortable: true,
    },
    {
      name: 'Lot Size',
      minWidth: '180px',
      selector: ({ lotSize }) => (lotSize || '-'), 
      sortable: true,
    },
    {
      name: 'Commission%',
      minWidth: '180px',
      selector: ({ commission }) => (commission || '-'), 
      sortable: true,
    },
    {
      name: 'Swap Long%',
      minWidth: '180px',
      selector: ({ swapLong }) => (swapLong || '-'), 
      sortable: true,
    },
    {
      name: 'Swap Short%',
      minWidth: '180px',
      selector: ({ swapShort }) => (swapShort || '-'), 
      sortable: true,
    },
    {
      name: 'Actions',
      minWidth: '200px',
      cell: (row) => (
        <div style={{ display: 'flex' }}>
          {
            !row.isSaving && (
              <button
                type="button"
                className="btn btn-success btn-sm me-1 p-1"
                onClick={() => showModal({
                  headerContent: <h3>{`Edit Account #${row.customId}`}</h3>,
                  bodyContent: <EditAccountModal row={row} />,
                })}
              >
                <FontAwesomeIcon icon={faPencil} className="header-icon" />
              </button>
            )
          }
          <button
            type="button"
            className="btn btn-danger btn-sm me-1 p-1"
            onClick={() => deleteAccount(row._id, row.isSaving)}
          >
            <FontAwesomeIcon icon={faTrash} className="header-icon" />
          </button>
        </div>

      ),
    },
  ];

  return (
    <div>
      <div className="action__btn-row">
        <button
          type="button"
          className="btn btn-default m-1 icon-btn btn-bg-green"
          onClick={() => showModal({
            headerContent: <h3>ADD ACCOUNT</h3>,
            bodyContent: <CreateAccountModal closeModal={hideModal} userId={userId} />,
          })}
        >
          <FontAwesomeIcon icon={faPlus} size="sm" />
          Add Account
        </button>

        <button
          type="button"
          className="btn btn-default m-1 icon-btn btn-bg-green"
          onClick={() => showModal({
            headerContent: <h3>ADD SAVING ACCOUNT</h3>,
            bodyContent: <CreateSavingAccountModal closeModal={hideModal} userId={userId} />,
          })}
        >
          <FontAwesomeIcon icon={faPlus} size="sm" />
          Add Saving Account
        </button>
      </div>
      <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
        <DataTable
          columns={columns}
          data={cfdAccounts}
          theme="solarizedd"
          fixedHeader
          pagination
          paginationServer
          paginationPerPage={rowsPerPage}
          paginationTotalRows={totalCount}
          paginationRowsPerPageOptions={[5, 10, 15]}
          onChangePage={(page) => setPage(page)}
          onChangeRowsPerPage={(currentRowsPerPage) => setRowsPerPage(currentRowsPerPage)}
          paginationDefaultPage={page}
          persistTableHead
          highlightOnHover
          progressPending={tableLoading}
          progressComponent={<div className="datatable-loader__background" />}
        />
      </div>
    </div>
  );
}

export default Accounts;
