import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { massUpdateCfdSettings } from '../../../redux/cfdSettings/cfdActions';

export function MassEditCFDSettingsModal({ settings, hideModal, clearRows }) {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState(
    {
      leverage: 100,
      lotSize: 100,
      lotStep: 1,
      commission: 0,
      swapLong: 0,
      swapShort: 0,
    }, 
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    const settingIds = settings.map(({ _id }) => (_id));
    const data = {
      ...formData,
      settingIds,
    };

    await dispatch(massUpdateCfdSettings(data));
    clearRows();
    hideModal();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value.replace(/[^\d.]/g, '').replace(/^0+(\d)/, '0$1'),
    }));    
  };

  return (
    <form onSubmit={handleSubmit}>
      
      <div className="form-group mb-2">
        <label htmlFor="leverage">Leverage</label>
        <input
          type="text"
          id="leverage"
          name="leverage"
          className="form-control"
          onChange={handleInputChange}
          value={formData.leverage ?? 0}
        />
      </div>
      
      <div className="form-group mb-2">
        <label htmlFor="lotStep">Lot Step</label>
        <input
          type="text"
          id="lotStep"
          name="lotStep"
          className="form-control"
          onChange={handleInputChange}
          value={formData.lotStep ?? 0}
        />
      </div>

      <div className="form-group mb-2">
        <label htmlFor="lotSize">Lot Size</label>
        <input
          type="text"
          id="lotSize"
          name="lotSize"
          className="form-control"
          onChange={handleInputChange}
          value={formData.lotSize ?? 0}
        />
      </div>

      <div className="form-group mb-2">
        <label htmlFor="commission">Commission %</label>
        <input
          type="text"
          id="commission"
          name="commission"
          className="form-control"
          onChange={handleInputChange}
          value={formData.commission ?? 0}
        />
      </div>

      <div className="form-group mb-2">
        <label htmlFor="swapLong">Swap Long %</label>
        <input
          type="text"
          id="swapLong"
          name="swapLong"
          className="form-control"
          onChange={handleInputChange}
          value={formData.swapLong ?? 0}
        />
      </div>

      <div className="form-group mb-2">
        <label htmlFor="swapShort">Swap Short %</label>
        <input
          type="text"
          id="swapShort"
          name="swapShort"
          className="form-control"
          onChange={handleInputChange}
          value={formData.swapShort ?? 0}
        />
      </div>

      <div className="m-2 d-flex justify-content-end">
        <button className="btn btn-default" type="submit">
          Save
        </button>
      </div>
    </form>
  );
}
