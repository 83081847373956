import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModalContext } from '../../../context';
import { updateCfdSettings } from '../../../redux/cfdSettings/cfdActions';

export function CfdSettingsModal({ row }) {
  const dispatch = useDispatch();
  const { hideModal } = useContext(ModalContext);

  const [formData, setFormData] = useState(
    {
      leverage: row.leverage,
      lotSize: row.lotSize,
      lotStep: row.lotStep,
      commission: row.commission,
      swapLong: row.swapLong,
      swapShort: row.swapShort,
      shift: row.shift,
    }, 
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    await dispatch(updateCfdSettings(row._id, formData));
    hideModal();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: name !== 'shift' ? value.replace(/[^\d.]/g, '').replace(/^0+(\d)/, '0$1') : value.replace(/[^-\d.]/g, '').replace(/^0+(\d)/, '$1'),
    }));    
  };

  return (
    <form onSubmit={handleSubmit}>
      
      <div className="form-group">
        <label htmlFor="leverage">Leverage</label>
        <input
          type="text"
          id="leverage"
          name="leverage"
          className="form-control"
          onChange={handleInputChange}
          value={formData.leverage}
        />
      </div>
      
      <div className="form-group">
        <label htmlFor="lotStep">Lot Step</label>
        <input
          type="text"
          id="lotStep"
          name="lotStep"
          className="form-control"
          onChange={handleInputChange}
          value={formData.lotStep}
        />
      </div>

      <div className="form-group">
        <label htmlFor="lotSize">Lot Size</label>
        <input
          type="text"
          id="lotSize"
          name="lotSize"
          className="form-control"
          onChange={handleInputChange}
          value={formData.lotSize}
        />
      </div>

      <div className="form-group">
        <label htmlFor="commission">Commission %</label>
        <input
          type="text"
          id="commission"
          name="commission"
          className="form-control"
          onChange={handleInputChange}
          value={formData.commission}
        />
      </div>

      <div className="form-group">
        <label htmlFor="swapLong">Swap Long %</label>
        <input
          type="text"
          id="swapLong"
          name="swapLong"
          className="form-control"
          onChange={handleInputChange}
          value={formData.swapLong}
        />
      </div>

      <div className="form-group">
        <label htmlFor="swapShort">Swap Short %</label>
        <input
          type="text"
          id="swapShort"
          name="swapShort"
          className="form-control"
          onChange={handleInputChange}
          value={formData.swapShort}
        />
      </div>

      <div className="form-group">
        <label htmlFor="swapShort">Shift</label>
        <input
          type="text"
          id="shift"
          name="shift"
          className="form-control"
          onChange={handleInputChange}
          value={formData.shift}
        />
      </div>

      <div className="m-2 d-flex justify-content-end">
        <button className="btn btn-default" type="submit">
          Save
        </button>
      </div>
    </form>
  );
}
