import React, {
  useEffect, useState, useRef,
} from 'react';
import Swal from 'sweetalert2';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  faRefresh, faTimes, faEdit, faCircleStop,
} from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from '../../hooks/useDebounce';
import { OpenOrdersSchema } from '../../DataTableSchemas/OpenOrdersSchema/OpenOrdersSchema';
import { openOrdersColumns } from '../../columnsDefaultConfig';
import { userOpenOrdersColumns } from '../../columnsDefaultConfig/userOpenOrdersColumns';
import { DatatableColumns, DatatableFilters } from '..';
import {
  createCRMFilter, deleteCRMFilter, getSubCRMUsers, updateCRMFilter, 
} from '../../redux/crmUser/crmUserActions';
import { getCfdSettings } from '../../redux/cfdSettings/cfdActions';
import { DownloadFile } from '../DownloadFile';
import { getCfdOpenOrders, exportFilteredDataToFile, massStopOrders } from '../../redux/cfdOrder/cfdOrderActions';
import socket from '../../services/socket';
import { EditOpenOrderModal } from './EditOpenOrderModal';

export function OpenOrdersTable({
  userId = '', localStorageConfig: {
    filtersStorageName = '',
    filterIdStorageName = '', 
    columnsStorageName = '',
    paginationStorageName = '', 
  }, 
}) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const datatableFiltersRef = useRef(null);

  const columnsJSON = localStorage.getItem(columnsStorageName);
  const filtersJSON = localStorage.getItem(filtersStorageName);

  const [tableLoading, setTableLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isPaginationDT, setIsPaginationDT] = useState(false);

  const columnConfigFile = userId && userId.length ? userOpenOrdersColumns : openOrdersColumns;

  const [columnConfig, setColumnConfig] = useState(columnConfigFile);
  const [filters, setFilters] = useState({});
  const [crmUserFilter, setCrmUserFilter] = useState([]);
  const [tradingTypeFilter, setTradingTypeFilter] = useState([]);
  const [coinPairsFilter, setCoinPairsFilter] = useState([]);
  const [userNameFilter, setUserNameFilter] = useState(null);
  const [openOrderId, setOpenOrderId] = useState(null);
  const [timeOpenedFilter, setTimeOpenedFilter] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [actionName, setActionName] = useState('');
  const [orderIdToPnl, setOrderIdToPnl] = useState({});
  const [inputFieldError, setInputFieldError] = useState({
    userIdIsNotValid: false,
    fullNameIsNotValid: false,
    errorMessage: '',
  });

  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);
  const { orders, totalCount } = useSelector((state) => state.cfdOrders);

  const actionIcon = actionName === 'Mass Stop Orders' ? faCircleStop : faEdit;

  const debounceCallback = ({ value, key }) => {
    if ((value.length >= 3 || value.length === 0 || Array.isArray(value)) && filters[key] !== value) {
      setFilters((prev) => ({ ...prev, [key]: value }));
    }
  };

  useDebounce(
    userNameFilter, 
    1000,
    (value) => debounceCallback({ value, key: 'clientName' }),
  );

  useDebounce(
    openOrderId, 
    1000,
    (value) => debounceCallback({ value, key: 'customId' }),
  );

  const setStoredPagination = () => {
    const openOrdersRowsJSON = localStorage.getItem(paginationStorageName);
    if (openOrdersRowsJSON) {
      const filterRows = JSON.parse(openOrdersRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
    }
    
    setIsPaginationDT(true);
  };

  const setStoredColumnsData = () => {
    if (columnsJSON) {
      const columns = JSON.parse(columnsJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };
  
  const handleClear = () => {
    setCoinPairsFilter([]);
    setUserNameFilter(null);
    setOpenOrderId(null);
    setCrmUserFilter([]);
    setTradingTypeFilter([]);
    setTimeOpenedFilter([]);
    setFilters({});
    localStorage.removeItem(filtersStorageName);
    localStorage.removeItem(filterIdStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const setStoredFilterData = () => {
    const filters = JSON.parse(filtersJSON);
    setFilters(filters);

    setCoinPairsFilter(filters.symbol || []);
    setTradingTypeFilter(filters['trading type'] || []);
    setUserNameFilter(filters.clientName || null);
    setOpenOrderId(filters.customId || null);
    setCrmUserFilter(filters['assigned to'] || []);
    setTimeOpenedFilter(filters['time opened'] || []);
  };

  const setCRMFilters = (filter) => {
    const {
      currency,
      assignedTo,
      type,
      timeOptionIds,
      fullName,
      transactionId,
    } = filter;

    setUserNameFilter(fullName || null);
    setOpenOrderId(transactionId || null);
    setCoinPairsFilter(currency || []);
    setTradingTypeFilter(type || []);
    setTimeOpenedFilter(timeOptionIds || []);
    setCrmUserFilter(assignedTo || []);
    setFilters({
      clientName: fullName,
      customId: transactionId,
      symbol: currency,
      'trading type': type,
      'assigned to': assignedTo,
      'time opened': timeOptionIds,
    });
    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const handleRowsPerPageChange = (currentRowsPerPage, page) => {
    setRowsPerPage(currentRowsPerPage);
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: rowsPerPage, page }));
  };

  const handlePageChange = (page) => {
    setPage(page);
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: rowsPerPage, page }));
  }; 

  const handleRefresh = async () => {
    setTableLoading(true);
    await dispatch(getCfdOpenOrders({
      page, rowsPerPage, filters, userId, 
    }));
    setTableLoading(false);
  };

  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const sort = async ({ selector }, sortDirection) => {
    const field = selector();
    const sort = { field, direction: sortDirection };
    setTableLoading(true);
    await dispatch(getCfdOpenOrders({
      page, rowsPerPage, filters, sort, userId,
    }));
    setTableLoading(false);
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);
    
    const data = {
      name,
      crmUserId,
      pathname,
      transactionId: filters?.customId || '',
      currency: filters.symbol || [],
      assignedTo: filters['assigned to'] || [],
      type: filters['trading type'] || [],
      timeOptionIds: filters['time opened'] || [],
      fullName: filters.clientName || '',
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        currency: filters.symbol || [],
        assignedTo: filters['assigned to'] || [],
        type: filters['trading type'] || [],
        timeOptionIds: filters['time opened'] || [],
        fullName: filters.clientName || '',
        transactionId: filters?.customId || '',
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const handleExportOrders = async (fileType) => {
    const columns = columnConfig.filter((obj) => obj.selected && obj.field);
    if (orders.length && columns.length) {
      setTableLoading(true);
      toast.success('Data export in progress. Please wait while we prepare the file.', {
        autoClose: 2000,
      });
      await exportFilteredDataToFile(orders, columns, fileType); 
      setTableLoading(false);
    } else {
      toastError('There is nothing to download.');
    }
  };

  const storeColumnConfig = (config) => {
    setColumnConfig(config);
    localStorage.setItem(columnsStorageName, JSON.stringify(config));
  };

  useEffect(() => {
    const loginData = localStorage.getItem('user');
    const data = JSON.parse(loginData);
    const id = data?.roleId;
    const uid = data?._id;

    setTableLoading(true);
    
    Promise.allSettled([
      dispatch(getSubCRMUsers(id, uid)),
      dispatch(getCfdSettings(id, uid)),
    ]);

    if (filtersJSON) {
      setStoredFilterData();
    }

    setStoredColumnsData();
    setStoredPagination();

    setTableLoading(false);
  }, []);

  useEffect(async () => {
    if (isPaginationDT) {
      localStorage.setItem(filtersStorageName, JSON.stringify(filters));

      setTableLoading(true);
      await dispatch(getCfdOpenOrders({
        page, rowsPerPage, filters, userId, 
      }));
      setTableLoading(false);
    }
  }, [page, rowsPerPage, isPaginationDT, filters]);

  useEffect(() => {
    const uniqueId = Date.now();

    if (orders.length) {
      socket.emit('getOpenOrderMarket', { orders, uniqueId });
    }

    socket.on(`receiveOpenOrderMarket&${uniqueId}`, (data) => {
      setOrderIdToPnl(data);
    });

    return () => {
      socket.off(`receiveOpenOrderMarket&${uniqueId}`);
      setOrderIdToPnl([]);
    };
  }, [orders]);

  const handleOrderTableSelect = async ({ selectedRows }) => {
    setSelectedOrders(selectedRows);
  };

  const handleMassStop = () => {
    Swal.fire({
      title: `Are you sure want to stop ${selectedOrders.length} orders?`,
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        const orderIds = selectedOrders.map(({ _id }) => (_id));
        const stopData = {};

        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(orderIdToPnl)) {
          if (orderIds.includes(key)) {
            stopData[key] = value.marketPrice;
          }
        }

        dispatch(massStopOrders(stopData));
      }
    });
  };

  const columns = OpenOrdersSchema({
    columnConfig,
    userNameFilter,
    setUserNameFilter,
    filters,
    setFilters,
    tradingTypeFilter,
    setTradingTypeFilter,
    crmUserFilter,
    setCrmUserFilter, 
    coinPairsFilter, 
    setCoinPairsFilter,
    EditOpenOrderModal,
    timeOpenedFilter, 
    setTimeOpenedFilter,
    orderIdToPnl,
    userId,
    openOrderId,
    setOpenOrderId,
    inputFieldError,
    setInputFieldError,
  });

  return (
    <div>
      <div className="action__btn-row">
        {
          crmFilters && (
            <DatatableFilters
              ref={datatableFiltersRef}
              filters={crmFilters}
              setFilters={setCRMFilters}
              createFilter={createUserCRMFilter}
              deleteFilter={deleteUserCRMFilter}
              updateFilter={updateUserCRMFilter}
              storageKey={filterIdStorageName}
              pathname={pathname}
            />
          )
        }
        <DownloadFile handleExport={handleExportOrders} />
        <DatatableColumns setColumns={storeColumnConfig} columnConfig={columnConfig} />
        <button type="button" className="btn btn-default icon-btn ms-1" onClick={handleClear}>
          <FontAwesomeIcon icon={faTimes} size="sm" />
          Clear
        </button>
        <button type="button" className="btn btn-default icon-btn ms-1" onClick={handleRefresh}>
          <FontAwesomeIcon icon={faRefresh} size="sm" />
          Refresh
        </button>
        <div style={{
          display: 'flex', alignItems: 'center', color: '#fff', marginLeft: '15px', 
        }}
        >
          <div>{`Selected ${selectedOrders.length} records`}</div>
          <Dropdown className="dt-actions__dropdown ms-1">
            <Dropdown.Toggle variant="" className="btn-default">{actionName || 'Select Action'}</Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setActionName('Mass Stop Orders')}>Mass Stop Orders</Dropdown.Item>
              {/* <Dropdown.Item onClick={() => setActionName('Mass Edit Orders')}>Mass Edit Orders</Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {
          actionName.length && (
            <button 
              disabled={!selectedOrders.length}
              type="button"
              className="btn btn-default ms-1"
              onClick={() => actionName === 'Mass Stop Orders' && handleMassStop()}
              style={{ 
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              <FontAwesomeIcon icon={actionIcon} size="sm" />
              { actionName }
            </button>
          )
        }
      </div>
      <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
        {
          isPaginationDT && (
            <DataTable
              columns={columns}
              data={orders}
              pagination
              paginationServer
              highlightOnHover
              paginationPerPage={rowsPerPage}
              paginationRowsPerPageOptions={[25, 50, 100, 500]}
              paginationTotalRows={totalCount}
              onChangeRowsPerPage={handleRowsPerPageChange}
              onChangePage={handlePageChange}
              onSelectedRowsChange={handleOrderTableSelect}
              onSort={sort}
              selectableRows
              sortServer
              persistTableHead
              selectableRowsHighlight
              selectableRowsVisibleOnly
              theme="solarizedd"
              className="specific-field-table custome-table-scroll"
              progressPending={tableLoading}
              progressComponent={<div className="datatable-loader__background" />}
            />
          )
        }
      </div>
    </div>
  );
}
