import React, {
  useContext, useEffect, useRef, useState, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import {
  faCheck, faPlus, faUpload, faTimes, faColumns, faRefresh, 
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  showAllUsers, deleteUsers, editUsers, editUser, getCountries, exportUsersToFile, updateExportDataState, updateUserState, deleteUser, moveUsersToManagers, prepareCSVFile,
} from '../../redux/users/userActions';
import { getBrands } from '../../redux/brand/brandActions';
import { deleteAdminCommentsByUserIds } from '../../redux/adminComment/adminCommentActions';
import { getSalesStatuses } from '../../redux/salesStatus/salesStatusActions';
import { getRole } from '../../redux/roles/roleActions';
import { getAffiliates } from '../../redux/affiliate/affiliateActions';
import {
  getSubCRMUsers, createCRMFilter, deleteCRMFilter, updateCRMFilter, 
} from '../../redux/crmUser/crmUserActions';
import { getCurrencyRates } from '../../redux/currencyRate/currencyRateActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';
import { UsersSchema } from '../../DataTableSchemas/UsersSchema';
import { DatatableActions, DatatableFilters } from '../../components';
import { usersColumns } from '../../columnsDefaultConfig';
import { DownloadFile } from '../../components/DownloadFile';
import { ModalContext } from '../../context';
import UserUploadModal from './UserUploadModal/UserUploadModal';
import { SET_UPLOADED_FILE_FIELDS } from '../../redux/users/userTypes';
import { AddCommentsModal } from '../../components/CalendarEventModals/AddCommentsModal';

function Users() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [, setRoleId] = useState('');
  const [, setUserId] = useState('');
  const [loader, setLoader] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [, setImgLoader] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedUsersIds, setSelectedUsersIds] = useState([]);
  const [type] = useState('0');
  const [show, setShow] = useState(true);
  const [agentUsers, setAgentUsers] = useState([]);
  const [assignedTo, setAssignedTo] = useState(null);
  const [csvFile, setCsvFile] = useState('');
  const [csvFileExtension, setCsvFileExtension] = useState('');
  const [timeInitiatedFilter, setTimeInitiatedFilter] = useState([]);
  const [lastCommentDate, setLastCommentDate] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [clientFullname, setClientFullname] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientPhone, setClientPhone] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [isPermissionChecked, setIsPermissionChecked] = useState(false);
  const [commentsModalIsOpen, setCommentsModalIsOpen] = useState(false);
  const [rowDataForComments, setRowDataForComments] = useState(null);

  const users = useSelector((state) => state?.users?.users);
  const allCountries = useSelector((state) => state?.users?.countries);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const success = useSelector((state) => state.users.success);
  const userDeleted = useSelector((state) => state?.users?.userDeleted);
  const userEditted = useSelector((state) => state?.users?.userEditted);
  const salesStatuses = useSelector((state) => state?.salesStatus?.salesStatuses);
  const crmUsers = useSelector((state) => state.crmUser?.crmUsers);
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);
  const allAffiliates = useSelector((state) => state.affiliate.affiliates);
  const usersCount = useSelector((state) => state.users.usersCount);
  const exportFilterdDataToFile = useSelector((state) => state.users.exportFilterdDataToFile);
  const brands = useSelector((state) => state.brands);
  const currencyRates = useSelector((state) => state.currencyRates);
  const uploadedFileFields = useSelector((state) => state.users.uploadedFileFields);

  // Search Boxes
  const [isCheckCountry, setIsCheckCountry] = useState([]);
  const [countriesToShow, setCountriesToShow] = useState([]);
  const [statusesToShow, setStatusesToShow] = useState([]);
  const [assignToShow, setAssignToShow] = useState([]);
  const [assignToDropdownShow, setAssignToDropdownShow] = useState(false);
  const [countryDropdownShow, setCountryDropdownShow] = useState(false);
  const [countryInput, setCountryInput] = useState('');
  const [statusInput, setStatusInput] = useState('');
  const [assignToInput, setAssignToInput] = useState('');
  const [affiliateFilter, setAffiliateFilter] = useState([]);
  // const [statusesToShow, setStatusesToShow] = useState(userTypeStatus);
  const [isCheckStatus, setIsCheckStatus] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [, setStatusDropdownShow] = useState(false);

  const [isCheckAssignTo, setIsCheckAssignTo] = useState([]);
  const filtersStorageName = 'DataTable_users/clients_filters'; 
  const filterIdStorageName = 'DataTable_users/clients_filter_id'; 
  const columnsStorageName = 'DataTable_users/clients_columns';
  const paginationStorageName = 'DataTable_users/clients_pagination';
  const clientsFilterJSON = localStorage.getItem(filtersStorageName);
  const clientsColumnsJSON = localStorage.getItem(columnsStorageName);
  const [clientsFilters, setClientsFilters] = useState({});
  const [columnConfig, setColumnConfig] = useState([]);
  const [clearRows, setCrearRows] = useState(false);

  const datatableFiltersRef = useRef(null);


  const { showModal, hideModal } = useContext(ModalContext);

  const handleUploadBtn = () => {
    showModal({
      headerContent: <h3>CONFIGURE FIELDS</h3>,
      bodyContent: (
        <UserUploadModal
          defaultRole="Client"
          csvFile={csvFile}
          csvFileExtension={csvFileExtension}
          // eslint-disable-next-line no-use-before-define
          handlePageChange={handlePageChange}
          uploadedFileFields={uploadedFileFields}
          hideModal={hideModal}
        />
      ),
    });
  };

  useEffect(() => {
    if (uploadedFileFields.length > 0) {
      handleUploadBtn();
    } else {
      hideModal();
    }
  }, [uploadedFileFields]);

  useEffect(() => () => dispatch({ type: SET_UPLOADED_FILE_FIELDS, payload: [] }), []);

  useEffect(() => {
    if (allCountries?.length) {
      setCountriesToShow(allCountries);
    }
  }, [allCountries]);

  useEffect(() => {
    if (salesStatuses?.length) {
      setStatusesToShow(salesStatuses);
    }
  }, [salesStatuses]);

  const padTo2Digits = (num) => num.toString().padStart(2, '0');

  const formatDate = (date) => (
    `${[
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-')
    } ${
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(':')}`
  );
  const deleteAction = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed === true) {
        const userIdData = localStorage.getItem('userId');
        const loginUserId = JSON.parse(userIdData);

        const data = {
          crmUserId: loginUserId,
        };

        dispatch(deleteUser(id, data));
      }
    });
  };

  const userAction = (id, actionType) => {
    Swal.fire({
      title: `Are you sure you want to ${actionType && actionType === 'block' ? 'block' : 'unblock'}?`,
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `${actionType && actionType === 'block' ? 'Block' : 'Unblock'}`,
    }).then((result) => {
      if (result.isConfirmed) {
        const data = { status: !(actionType && actionType === 'block') };
        dispatch(editUser(id, data));
      }
    });
  };

  const handleStatusChange2 = (_id, e) => {
    if (_id) {
      const newUsers = [...users];
      const objIndex = newUsers.findIndex(((obj) => obj?.users?._id === _id));
      newUsers[objIndex].users.salesStatusId = e.target.value;
      // setUsers([...newUsers]);
      const data = { salesStatusId: e.target.value ? e.target.value : null };
      dispatch(editUser(_id, data, false));
    }
  };

  const isFiltersEmpty = (filters) => {
    const isEmpty = Object.keys(filters).every((key) => {
      if (filters[key].length) return false;

      return true;
    });

    return isEmpty;
  };

  const setStoredFilterData = async (id, uid, clientType, currencies) => {
    if (clientsFilterJSON && !isFiltersEmpty(JSON.parse(clientsFilterJSON))) {
      const filters = JSON.parse(clientsFilterJSON);
      setClientsFilters(filters);

      setClientFullname(filters.fullName || '');
      setClientEmail(filters.email || '');
      setClientPhone(filters.phone || '');
      setIsCheckCountry(filters.country || []);
      setIsCheckStatus(filters.status || []);
      setIsCheckAssignTo(filters['assigned to'] || []);
      setAffiliateFilter(filters['affiliate name'] || []);
      setTimeInitiatedFilter(filters.created || []);
      setLastCommentDate(filters['last comment'] || []);
      setSelectedBrands(filters.brand || []);
    } else {
      await dispatch(showAllUsers(type, id, uid, clientType, '', page, rowsPerPage, {}, currencies));
    }
  };

  async function fetchData() {
    const loginData = localStorage.getItem('user');
    const data = JSON.parse(loginData);
    const id = data?.roleId;
    const uid = data?._id;
    const clientType = 2;

    setRoleId(id);
    await setUserId(uid);
    await dispatch(getRole(id));
    setStoredFilterData(id, uid, clientType, currencyRates);
    await dispatch(getCountries());
    await dispatch(getSalesStatuses());
    await dispatch(getSubCRMUsers(id, uid));
    await dispatch(getAffiliates());
    await dispatch(getBrands());
  }

  const refresh = async () => {
    const loginData = localStorage.getItem('user');
    const data = JSON.parse(loginData);
    const id = data?.roleId;
    const uid = data?._id;

    await dispatch(showAllUsers(type, id, uid, 2, '', page, rowsPerPage, clientsFilters, currencyRates));
  };

  const setStoredColumnsData = () => {
    if (clientsColumnsJSON) {
      const columns = JSON.parse(clientsColumnsJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };
  
  const setStoredPagination = () => {
    const clientRowsJSON = localStorage.getItem(paginationStorageName);
    if (clientRowsJSON) {
      const filterRows = JSON.parse(clientRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
    }
    
    setIsPaginationDT(true);
  };

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText('');
    }
    setClientEmail('');
    setClientFullname('');
    setClientPhone('');
    setIsCheckAssignTo([]);
    setIsCheckCountry([]);
    setIsCheckStatus([]);
    setAffiliateFilter([]);
    setTimeInitiatedFilter([]);
    setLastCommentDate([]);
    setSelectedBrands([]);
    setClientsFilters({
      email: '',
      phone: '',
      fullName: '',
    });
    localStorage.removeItem(filtersStorageName);
    localStorage.removeItem(filterIdStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  useEffect(() => {
    dispatch(getCurrencyRates('USD'));
  }, []);

  useEffect(() => {
    if (crmUsers?.length) {
      const allAgents = crmUsers?.map((agen) => ({ label: `${agen.firstName} ${agen.lastName}`, value: agen._id }));
      setAgentUsers(allAgents);
      setAssignToShow(crmUsers);
      setAssignedTo({ label: allAgents?.[0]?.label, value: allAgents?.[0]?.value });
    }
  }, [crmUsers]);

  useEffect(() => {
    if (!isPermissionChecked && permissionName && permissionName.length) {
      setColumnConfig(usersColumns(permissionName));
      setIsPermissionChecked(true);
      if (permissionName.includes('clients')) {
        setStoredPagination();
      }
    }
  }, [permissionName]);

  useEffect(() => {
    if (isPaginationDT) {
      setTableLoading(true);
      fetchData();
      setStoredColumnsData();
    }
  }, [isPaginationDT]);

  const getUsers = (page, rowsPerPage, filters) => {
    const loginData = localStorage.getItem('user');
    const data = JSON.parse(loginData);
    const id = data?.roleId;
    const uid = data?._id;

    return dispatch(showAllUsers(type, id, uid, 2, '', page, rowsPerPage, filters, currencyRates));
  };
  
  const handleRowsPerPageChange = async (currentRowsPerPage, page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage }));
    setTableLoading(true);
    await getUsers(page, currentRowsPerPage, clientsFilters);
    setTableLoading(false);
  };

  const handlePageChange = async (page) => {
    setPage(page);
    setTableLoading(true);
    await getUsers(page, rowsPerPage, clientsFilters);
    setTableLoading(false);
  };

  useEffect(() => {
    if (type === '0') setShow(true);
    else setShow(false);
    if (success) {
      setLoader(false);
      setTableLoading(false);
      dispatch(updateUserState());
    }
  }, [type, success, userDeleted, userEditted]);

  const setCRMFilters = (filter) => {
    const {
      fullName, email, phone, country, status, assignedTo, timeOptionIds, affiliate, brand, 
    } = filter;

    setClientEmail(email);
    setClientFullname(fullName);
    setClientPhone(phone);
    setIsCheckAssignTo(assignedTo);
    setAffiliateFilter(affiliate);
    setIsCheckCountry(country);
    setIsCheckStatus(status);
    setSelectedBrands(brand);
    setClientsFilters({
      fullName,
      email,
      phone,
      country,
      status,
      timeOptionIds,
      brand,
      'assigned to': assignedTo,
      'affiliate name': affiliate,
    });
    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const fireSwal = (title) => Swal.fire({
    title,
    html: '',
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
  });
  
  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const deleteUsersInBulk = () => {
    const promise = new Promise((res) => {
      if (selectedUsersIds.length) {
        fireSwal('Are you sure you want to delete user(s)?')
          .then(async (result) => {
            if (result.isConfirmed) {
              setLoader(true);
              const data = {
                ids: selectedUsersIds,
              };

              setSelectedUsersIds([]);
              setCrearRows(true);
              await dispatch(deleteUsers(data));
              await refresh();

              res(true);
            }
          })
          .finally(() => {
            setLoader(false);
          });
      } else {
        toastError('Select atleast one user to complete this action.');
      }
    });

    return promise;
  };

  const assignUsersToAgentInBulk = () => {
    if (selectedUsersIds?.length > 0) {
      Swal.fire({
        title: 'Are you sure you want to assign all users to selected agent?',
        html: '',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          // Assign users to Agents
          const data = {
            ids: selectedUsersIds,
            assignedTo: assignedTo.value,
          };
          await dispatch(editUsers(data));
        }
      });
    } else {
      toastError('Select atleast one user to complete this action.');
    }
  };

  const moveUsersInBulk = () => {
    if (selectedUsersIds?.length > 0) {
      Swal.fire({
        title: 'Are you sure you want to move user(s) to lead category?',
        html: '',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          // Move to Leads
          const data = {
            ids: selectedUsersIds,
            clientType: 1,
          };
          await dispatch(editUsers(data));
          setSelectedUsersIds([]);
        }
      });
    } else {
      toastError('Select atleast one user to complete this action.');
    }
  };

  const deleteCommentsInBulk = () => {
    const promise = new Promise((res) => {
      if (selectedUsersIds.length) {
        fireSwal('Are you sure you want to delete all comments for this user(s)?')
          .then(async (result) => {
            if (result.isConfirmed) {
              setCrearRows(true);
              setSelectedUsersIds([]);
              await dispatch(deleteAdminCommentsByUserIds(selectedUsersIds));
              await refresh();

              res(true);
            }
          });
      } else {
        toastError('Select atleast one user to complete this action.');
      }
    });

    return promise;
  };

  const moveUsersToManagersInBulk = (managerIds) => {
    const promise = new Promise((res) => {
      if (selectedUsersIds.length) {
        fireSwal('Are you sure you want to move all this user(s) to selected manager(s)?')
          .then(async (result) => {
            if (result.isConfirmed) {
              setLoader(true);
              const data = {
                userIds: selectedUsersIds,
                managerIds,
              };
  
              setCrearRows(true);
              setSelectedUsersIds([]);
              await dispatch(moveUsersToManagers(data));
              await refresh();

              res(true);
            }
          })
          .finally(() => {
            setLoader(false);
          });
      } else {
        toastError('Select atleast one user to complete this action.');
      }
    });

    return promise;
  };

  const editUsersInBulk = (data, swalTitle) => {
    const promise = new Promise((res) => {
      if (selectedUsersIds.length) {
        fireSwal(swalTitle)
          .then(async (result) => {
            if (result.isConfirmed) {
              setLoader(true);
              setCrearRows(true);
              setSelectedUsersIds([]);
              await dispatch(editUsers(data));
              await refresh();

              res(true);
            }
          })
          .finally(() => {
            setLoader(false);
          });
      } else {
        toastError('Select atleast one user to complete this action.');
      }
    });

    return promise;
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);

    if (!filters) {
      toastError('Select atleast one filter to complete this action.');

      return;
    }

    const data = {
      name,
      crmUserId,
      pathname,
      fullName: filters.fullName || '',
      email: filters.email || '',
      phone: filters.phone || '',
      country: filters.country || [],
      status: filters.status || [],
      assignedTo: filters['assigned to'] || [],
      affiliate: filters['affiliate name'] || [],
      timeOptionIds: filters.created || [],
      brand: filters.brand || [],
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        fullName: filters.fullName || '',
        email: filters.email || '',
        phone: filters.phone || '',
        country: filters.country || [],
        status: filters.status || [],
        assignedTo: filters['assigned to'] || [],
        affiliate: filters['affiliate name'] || [],
        timeOptionIds: filters.created || [],
        brand: filters.brand || [],
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  useEffect(async () => {
    if (clientsFilters && Object.keys(clientsFilters).length) {
      localStorage.setItem(filtersStorageName, JSON.stringify(clientsFilters));
      setPage(1);
      setTableLoading(true);
      await getUsers(1, rowsPerPage, clientsFilters);
      setTableLoading(false);
    }
  }, [clientsFilters]);

  const handleUserSelectedChange = async ({ selectedRows }) => {
    const ids = await selectedRows.map((row) => row._id);
    await setSelectedUsersIds(ids);
  };

  const searchByName = ({ target: { value } }) => {
    setClientFullname(value);

    const delayDebounceFn = setTimeout(() => {
      if (value.length >= 3 || !value.length) setClientsFilters({ ...clientsFilters, fullName: value });
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  };

  const searchByEmail = ({ target: { value } }) => {
    setClientEmail(value);

    const delayDebounceFn = setTimeout(() => {
      if (value.length >= 3 || !value.length) setClientsFilters({ ...clientsFilters, email: value });
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  };

  const searchByPhone = ({ target: { value } }) => {
    setClientPhone(value);

    const delayDebounceFn = setTimeout(() => {
      if (value.length >= 3 || !value.length) setClientsFilters({ ...clientsFilters, phone: value });
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  };

  const handleCSVChange = (e, fileType) => {
    const file = e.target.files[0];
    let typeCheck = ['csv'];

    if (fileType !== 'csv') {
      typeCheck = ['xlsx'];
    }

    if (file) {
      const reader = new window.FileReader();
      reader.readAsDataURL(file);
      setImgLoader(true);
      reader.onloadend = () => {
        const fileExtension = file.name
          .split('.')
          .pop()
          .toLowerCase();

        if (
          typeCheck.indexOf(
            fileExtension,
          ) > -1
        ) {
          setCsvFileExtension(fileExtension);
          setCsvFile(file);
        } else {
          setImgLoader(false);
          Swal.fire({
            text:
                     `The file must be a file of type: ${fileType}`,
            icon: 'error',
            customClass: 'custom-alt',
          });
        }
      };
    }
  };

  useEffect(async () => {
    const formData = new FormData();

    if (csvFile) {
      formData.append('csvFile', csvFile);
      formData.append('csvFileExtension', csvFileExtension);
      
      setLoader(true);
      await dispatch(prepareCSVFile(formData));
      setLoader(false);
    }

    setImgLoader(false);
  }, [csvFile, csvFileExtension]);

  const handleExportUser = async (fileType) => {
    const columns = columnConfig.filter(({ selected, field }) => selected && field);
    if (users.length && columns.length) {
      const { roleId, _id: userId } = JSON.parse(localStorage.getItem('user'));

      const data = {
        userType: type,
        roleId,
        userId,
        clientType: 2,
        affiliateId: '',
        page, 
        perPage: usersCount, 
        filters: clientsFilters, 
        currencies: currencyRates,
        fileType,
        columns,
        selectedUsersIds,
      };

      toast.success('Export in progress. Please wait.', {
        autoClose: 1000,
      });
      dispatch(exportUsersToFile(data));
    } else {
      toastError('There is nothing to download.');
    }
  };
  
  useEffect(() => {
    if (exportFilterdDataToFile) {
      dispatch(updateExportDataState()); 
    }
  }, [exportFilterdDataToFile]);

  const handleParentDivClick = (e) => {
    const parentHasClass = e?.target?.closest('.dropdown-check-nostyle') !== null;
    if (!parentHasClass) {
      setCountryDropdownShow(false);
      setStatusDropdownShow(false);
      setAssignToDropdownShow(false);
      setCountryInput('');
      setCountriesToShow(allCountries);
      setStatusInput('');
      setStatusesToShow(salesStatuses);
      // setStatusesToShow(userTypeStatus);
      setAssignToInput('');
      setAssignToShow(crmUsers);
    }
  };

  const toggleColumn = (name) => {    
    const updatedColumns = columnConfig.map((column) => {
      if (column.name === name) {
        return { ...column, selected: !column.selected };
      }

      return column;
    });

    setColumnConfig(updatedColumns);
    localStorage.setItem(columnsStorageName, JSON.stringify(updatedColumns));
  };

  const addCommentHandler = (row) => {
    setRowDataForComments(row);
    setCommentsModalIsOpen(true);
  };

  const columns = UsersSchema(
    permissionName,
    formatDate,
    allAffiliates,
    affiliateFilter, 
    setAffiliateFilter,
    salesStatuses,
    countriesToShow,
    userAction,
    isCheckStatus,
    setIsCheckStatus,
    handleStatusChange2,
    assignToShow,
    isCheckAssignTo,
    deleteAction,
    clientFullname,
    searchByName,
    clientEmail,
    searchByEmail,
    clientPhone,
    searchByPhone,
    isCheckCountry, 
    setIsCheckCountry,
    setIsCheckAssignTo,
    columnConfig,
    clientsFilters,
    setClientsFilters,
    setTimeInitiatedFilter,
    timeInitiatedFilter,
    lastCommentDate, 
    setLastCommentDate,
    brands,
    selectedBrands,
    setSelectedBrands,
    currencyRates,
    addCommentHandler,
  );

  return (
    loader ? <FullPageTransparentLoader />
      : (
        <div className="content-wrapper right-content-wrapper" onClick={(e) => handleParentDivClick(e)}>
          <div className="content-box">
            <h3>Clients</h3>
            <div className="action__btn-row">
              {show && permissionName && permissionName.length > 0 && permissionName.includes('add_user')
                ? (
                  <Link 
                    to="/add-user"
                    className="btn btn-default" 
                    style={{ 
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                      backgroundColor: 'green',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      size="sm"
                    />
                    Add
                  </Link>
                )
                : null}
              {show && permissionName && permissionName.length > 0 && permissionName.includes('add_user_with_csv_file')
                ? (
                  <Dropdown className="leads-download-dropdown ms-1">
                    <Dropdown.Toggle 
                      variant="" 
                      className="btn-default" 
                      id="dropdown-basic"
                      style={{ 
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                        backgroundColor: 'green',
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faUpload}
                        size="xs"
                      />
                      Upload
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="import-csv-btn text-center mb-1">
                        <label htmlFor="file-import-xlsx" className="btn btn-default">Excel</label>
                        <input style={{ display: 'none' }} id="file-import-xlsx" type="file" name="csvFile" accept=".xlsx" onChange={(e) => handleCSVChange(e, 'xlsx')} />
                      </div>
                      <div className="import-csv-btn text-center">
                        <label htmlFor="file-import-csv" className="btn btn-default">CSV</label>
                        <input style={{ display: 'none' }} id="file-import-csv" type="file" name="csvFile" accept=".csv" onChange={(e) => handleCSVChange(e, 'csv')} />
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                )
                : null}
              {show && permissionName && permissionName.length > 0 && permissionName.includes('export_leads_to_file')
                ? (
                  <DownloadFile handleExport={handleExportUser} />
                )
                : null}
              {
                  show ? (
                    <Dropdown className="leads-columns-dropdown ms-1">
                      <Dropdown.Toggle 
                        variant="" 
                        className="btn-default"
                        style={{ 
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faColumns}
                          size="xs"
                          style={{ padding: '5px' }}
                        />
                        Columns
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <ul className="leads-columns-list">
                          {columnConfig.map((column) => (
                            <li className="leads-columns-option" key={column.name} onClick={() => toggleColumn(column.name)}>
                              {column.selected ? (
                                <FontAwesomeIcon
                                  size="sm"
                                  icon={faCheck}
                                  color="#6E7F95"
                                />
                              ) : null}
                              <span className="leads-columns-option__name">{column.name}</span>
                            </li>
                          ))}
                        </ul>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : null
                }
              {
                  show && crmFilters ? (
                    <DatatableFilters 
                      ref={datatableFiltersRef}
                      filters={crmFilters} 
                      setFilters={setCRMFilters}
                      createFilter={createUserCRMFilter}
                      deleteFilter={deleteUserCRMFilter}
                      updateFilter={updateUserCRMFilter}
                      storageKey={filterIdStorageName}
                      pathname={pathname}
                    /> 
                  ) : null
                }
              {
                  show ? (
                    <button
                      style={{ 
                        marginLeft: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                      type="button"
                      className="btn btn-default"
                      onClick={handleClear}
                    >
                      <FontAwesomeIcon icon={faTimes} size="sm" />
                      Clear
                    </button>
                  ) : null
                }
              {
                  show ? (
                    <button 
                      style={{ 
                        marginLeft: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                      }} 
                      type="button" 
                      className="btn btn-default" 
                      onClick={refresh}
                    >
                      <FontAwesomeIcon icon={faRefresh} size="sm" />
                      Refresh
                    </button>
                  ) : null
                }
              {
                  show && permissionName && permissionName.length > 0 && permissionName.includes('edit_user') ? (
                    <DatatableActions 
                      ids={selectedUsersIds} 
                      managers={crmUsers} 
                      statuses={salesStatuses} 
                      countries={allCountries} 
                      deleteUsers={deleteUsersInBulk}
                      deleteComments={deleteCommentsInBulk}
                      moveUsersToManagers={moveUsersToManagersInBulk}
                      editUsers={editUsersInBulk}
                      clientType="2"
                    />
                  ) : null
                }
            </div>
            <br />
            {/* <div>
              <div className="form-group col-md-12 mb-3">
                <label className="control-label">Global Search</label>
                <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
              </div>
            </div> */}
            {/* <div className="d-inline-flex w-100">
              {show && permissionName && permissionName.length > 0 && permissionName.includes('assign_to_agent')
                ? (
                  <div className="listing-dropdown-wrapper d-inline-flex p-3">
                    <div className="btn-group btn-group-toggle" data-toggle="buttons">
                        <button type="button" className="btn btn-secondary btn-sm me-1 p-1" style={{ position: 'relative' }} onClick={() => moveUsersInBulk()}>Move to Leads</button>
                        <button type="button" className="btn btn-danger btn-sm me-1 p-1" style={{ position: 'relative' }} onClick={() => deleteUsersInBulk()}>
                          <BsTrashFill color="white" size={20} role="button" />
                        </button>
                        <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
                    </div>
                    <div className="form-group col-lg-3 col-md-6 col-12" style={{ position: 'relative', zIndex: '10' }}>
                      <Select
                        value={assignedTo}
                        onChange={handleAssignedToChange}
                        options={agentUsers}
                        styles={colourStyles}
                      />
                    </div>
                    <button type="button" className="btn btn-default" onClick={() => assignUsersToAgentInBulk()}>Assign to Agent</button>
                  </div>
                )
                : null}
              {
                           show && permissionName && permissionName.length > 0 && permissionName.includes('assign_to_agent') ?
                              <div className='listing-dropdown-wrapper d-inline-flex pt-3'>
                                 <div className="form-group col-lg-3 col-md-6 col-12" style={{ position: 'relative', zIndex: '10' }}>
                                    <Select
                                       value={selectedUserStatus}
                                       onChange={handleStatusChange}
                                       options={userTypeStatus}
                                       styles={colourStyles}
                                    />
                                 </div>
                                 <button className="btn btn-default" onClick={() => changeUsersStatusInBulk()}>Change Status</button>
                              </div>
                              : null
                        }
            </div> */}
            <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
              {
                isPaginationDT
                  && (
                    <DataTable
                      rows={users?._id}
                      columns={columns}
                      data={users}
                      pagination
                      paginationServer
                      highlightOnHover
                      paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                      paginationTotalRows={usersCount}
                      paginationPerPage={rowsPerPage}
                      paginationRowsPerPageOptions={[25, 50, 200, 500]}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      onChangePage={handlePageChange}
                      persistTableHead
                      selectableRows
                      onSelectedRowsChange={handleUserSelectedChange}
                      clearSelectedRows={clearRows}
                      selectableRowSelected={users?._id}
                      selectableRowsHighlight
                      selectableRowsVisibleOnly
                      theme="solarizedd"
                      progressPending={tableLoading}
                      progressComponent={<div className="datatable-loader__background" />}
                    />
                  )
            }
            </div>
          </div>
          {commentsModalIsOpen && <AddCommentsModal showModal={commentsModalIsOpen} setShowModal={setCommentsModalIsOpen} row={rowDataForComments} />}
        </div>
      )
  );
}

export default Users;
