import {
  GET_CFD_ORDERS, UPDATE_CFD_ORDER, REVERT_CFD_ORDER, STOP_CFD_ORDER, MASS_CFD_ORDER_CREATION, MASS_CFD_ORDER_STOP,
} from './cfdOrderTypes';

const initialState = {
  massCreationResults: [],
  orders: [],
  totalCount: 0,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case MASS_CFD_ORDER_STOP:
      return {
        ...state,
        orders: state.orders.filter(({ _id }) => !action.payload.ids.includes(_id)),
      };
    case MASS_CFD_ORDER_CREATION:
      return {
        ...state,
        massCreationResults: action.payload,
      };
    case GET_CFD_ORDERS:
      return action.payload;
    case UPDATE_CFD_ORDER:
      return {
        totalCount: state.totalCount,
        orders: state.orders.map((order) => {
          if (order._id === action.payload._id) return action.payload;

          return order;
        }),
      };
    case REVERT_CFD_ORDER:
      return {
        totalCount: Number(state.totalCount) - 1,
        orders: state.orders.filter((order) => order._id !== action.payload._id),
      };

    case STOP_CFD_ORDER: 
      return {
        totalCount: Number(state.totalCount) - 1,
        orders: state.orders.filter((order) => order._id !== action.payload._id),
      };
    default:
      return state;
  }
};
