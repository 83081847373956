
import {
  ADD_SMTP_PRESET,
  DELETE_SMTP_PRESET,
  DELETE_USER_SMTP_PRESET,
  UPDATE_SMTP_PRESET,
  SET_TOTAL_COUNT,
  SET_IS_PRESETS_LOADING,
  SET_SMTP_PRESETS,
  SET_USER_SMTP_PRESETS,
  ADD_USER_SMTP_PRESETS,
  SET_SELECTED_USER_SMTP_PRESET,
  SET_SMTP_AGENT_SEARCH_FILTER,
  SET_SMTP_PROVIDER_SEARCH_FILTER,
  SET_SMTP_EMAIL_SEARCH_FILTER,
  SET_SMTP_PASSWORD_SEARCH_FILTER,
  SET_SMTP_DESCRIPTION_SEARCH_FILTER,
} from './smtpPresetsTypes';

const localStorageSelectedPresetsJSON = localStorage.getItem('Header/smtp-preset');
const localStorageSelectedPresets = JSON.parse(localStorageSelectedPresetsJSON);

const initialState = {
  smtpPresets: [],
  isLoading: false,
  totalCount: 0,
  userPresets: [],
  selectedUserPreset: localStorageSelectedPresets,
  searchFilters: {
    agent: null,
    provider: null,
    email: null,
    password: null,
    description: null,
  },
};

const smtpPresetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SMTP_AGENT_SEARCH_FILTER:
      return {
        ...state,
        searchFilters: {
          ...state.searchFilters,
          agent: action.payload,
        },
      };
    case SET_SMTP_PROVIDER_SEARCH_FILTER:
      return {
        ...state,
        searchFilters: {
          ...state.searchFilters,
          provider: action.payload,
        },
      };
    case SET_SMTP_EMAIL_SEARCH_FILTER:
      return {
        ...state,
        searchFilters: {
          ...state.searchFilters,
          email: action.payload,
        },
      };
    case SET_SMTP_PASSWORD_SEARCH_FILTER:
      return {
        ...state,
        searchFilters: {
          ...state.searchFilters,
          password: action.payload,
        },
      };
    case SET_SMTP_DESCRIPTION_SEARCH_FILTER:
      return {
        ...state,
        searchFilters: {
          ...state.searchFilters,
          description: action.payload,
        },
      };
    case SET_SELECTED_USER_SMTP_PRESET:
      if (action.payload === null) {
        localStorage.removeItem('Header/smtp-preset');
      } else {
        localStorage.setItem('Header/smtp-preset', JSON.stringify(action.payload));
      }

      return {
        ...state,
        selectedUserPreset: action.payload,
      };
    case SET_USER_SMTP_PRESETS:
      return {
        ...state,
        userPresets: action.payload,
      };
    case ADD_USER_SMTP_PRESETS: {
      const selectedPreset = localStorage.getItem('Header/smtp-preset');
      console.log('selectedPreset: ', selectedPreset);

      if (selectedPreset === null) {
        console.log('action.payload: ', action.payload);
        localStorage.setItem('Header/smtp-preset', JSON.stringify(action.payload));
      }

      return {
        ...state,
        userPresets: [
          ...state.userPresets,
          action.payload,
        ],
        selectedUserPreset: action.payload,
      };
    }
    case SET_IS_PRESETS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_TOTAL_COUNT:
      return {
        ...state,
        totalCount: action.payload,
      };
    case SET_SMTP_PRESETS:
      return {
        ...state,
        smtpPresets: action.payload,
      };
    case ADD_SMTP_PRESET:
      return {
        ...state,
        smtpPresets: [...state.smtpPresets, action.payload],
      };
    case DELETE_SMTP_PRESET:
      return {
        ...state,
        smtpPresets: state.smtpPresets.filter((preset) => preset._id !== action.payload),
      };
    case DELETE_USER_SMTP_PRESET: {
      const selectedPreset = localStorage.getItem('Header/smtp-preset');

      if (selectedPreset !== null) {
        const selectedPresetJSON = JSON.parse(selectedPreset);

        if (selectedPresetJSON._id === action.payload) {
          localStorage.removeItem('Header/smtp-preset');
        }
      }

      return {
        ...state,
        userPresets: state.userPresets.filter((preset) => preset._id !== action.payload),
      };
    }
    case UPDATE_SMTP_PRESET: {
      const selectedPreset = localStorage.getItem('Header/smtp-preset');

      if (selectedPreset !== null) {
        const selectedPresetJSON = JSON.parse(selectedPreset);
        
        if (selectedPresetJSON._id === action.payload._id) {
          localStorage.setItem('Header/smtp-preset', JSON.stringify(action.payload));
        }
      }

      return {
        ...state,
        smtpPresets: state.smtpPresets.map((preset) => (preset._id === action.payload._id ? action.payload : preset)),
      };
    }
    default:
      return state;
  }
};

export default smtpPresetsReducer;
