export const nonNegativeAmount = (amount) => Math.max(amount, 0);

export const addShift = (close, settings, symbolShift = 0) => {
  if (!settings && symbolShift === 0) return close;
  const selectedSettings = {};

  selectedSettings.isPositive = symbolShift >= 0;
  selectedSettings.shift = selectedSettings.isPositive ? symbolShift : Math.abs(symbolShift);

  if (settings) {
    selectedSettings.isPositive = settings.isPositive;
    selectedSettings.shift = settings.shift;
  }

  const { shift, isPositive } = selectedSettings;

  const setPrecisionNumber = (closeFrom) => {
    const closeFromNum = Number(closeFrom);
    let precisionNumber = 1;
          
    if (closeFromNum < 1) {
      precisionNumber = 6;
    } else if (closeFromNum >= 1 && closeFromNum < 10) {
      precisionNumber = 5;
    } else if (closeFromNum >= 10 && closeFromNum < 100) {
      precisionNumber = 4;
    } else if (closeFromNum >= 100 && closeFromNum < 1000) {
      precisionNumber = 3;
    } else if (closeFromNum >= 1000 && closeFromNum < 10000) {
      precisionNumber = 2;
    } else if (closeFromNum >= 10000) {
      precisionNumber = 1;
    }
          
    return precisionNumber;
  };
    
  const addZerosToRightPartStart = (rightPart, difference) => {
    for (let i = 0; i < difference; i++) {
      rightPart = `0${rightPart}`;
    }
      
    return rightPart;
  };
  
  if (close.indexOf('.') === -1) close += '.000000';
    
  const shiftStr = String(shift);
  const [from, to] = close.split('.');
  const cuttedTo = to.substring(0, setPrecisionNumber(from));
    
  let shiftRightPart = shiftStr.substring(shiftStr.length - cuttedTo.length);
  if (cuttedTo.length > shiftRightPart.length) {
    shiftRightPart = addZerosToRightPartStart(shiftRightPart, cuttedTo.length - shiftRightPart.length);
  } 
  const shiftLeftPart = shiftStr.substring(0, shiftStr.length - shiftRightPart.length) || '0';
  const convertedShift = `${shiftLeftPart}.${shiftRightPart}`;
    
  return isPositive ? Number(close) + Number(convertedShift) : Number(close) - Number(convertedShift);
};
