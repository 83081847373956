/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  getUserDetails, showAllUsers, editUser,
} from '../../redux/users/userActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';
import EditUser from './UserDetailComponents/EditUser';
import Deposit from './UserDetailComponents/Deposit';
import WithdrawalComponent from './UserDetailComponents/WithdrawalComponent';
import ActiveOrder from './UserDetailComponents/ActiveOrder';
import ExchangeList from './UserDetailComponents/ExchangeList';
import TradeHistory from './UserDetailComponents/TradeHistory';
import Accounts from './UserDetailComponents/Accounts/Accounts';
import { TradingSettings } from './UserDetailComponents/TradingSettings';
import { getLocalDataByCountryCode } from '../../components';
import { SavingInvestmentsDatatable } from '../../DataTableSchemas/SavingInvestmentsDatatable';

function UserDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [user, setUser] = useState('');
  const [, setSelectedFlagCountry] = useState('');
  const [, setDateOfBirth] = useState('');
  const [loader, setLoader] = useState(false);
  const [activeTab, setActveTab] = useState('edit');
  const [selectedUserType, setSelectedUserType] = useState(1);

  const userData = useSelector((state) => state?.users?.user);
  const userEditted = useSelector((state) => state?.users?.userEditted);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const allUsersList = useSelector((state) => state?.users?.users);
  const isUserCanSeeTradeSettings = permissionName && permissionName.includes('trade_management');
  const isUserCanEditUser = permissionName && permissionName.includes('edit_user');
  const isUserCanSeeLeads = permissionName && permissionName.includes('leads');
  const isUserCanSeeClients = permissionName && permissionName.includes('clients');
  const isUserCanSeeSaving = permissionName && permissionName.includes('saving_management');

  const userIndex = allUsersList.indexOf(allUsersList.filter((item) => item._id === user._id)[0]);
  const prevUser = userIndex > 0 ? allUsersList[userIndex - 1] : allUsersList[allUsersList.length - 1];
  const nextUser = userIndex < allUsersList.length - 1 ? allUsersList[userIndex + 1] : allUsersList[0];

  useEffect(() => {
    const fetchData = () => {
      dispatch(getUserDetails(id));
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchData = () => {
      const loginData = localStorage.getItem('user');
      const data = JSON.parse(loginData);
      const id = data?.roleId;
      const uid = data?._id;
      dispatch(showAllUsers('1', id, uid, userData.clientType));
    };
    if (!allUsersList.length) fetchData();
  }, [user]);

  useEffect(() => {
    if (userData.clientType && permissionName?.length) {
      if ((userData.clientType === 1 && isUserCanSeeLeads) || (userData.clientType === 2 && isUserCanSeeClients)) {
        setLoader(true);
        setUser(userData);
        const { dateOfBirth, countryCode, clientType } = userData;
        if (dateOfBirth) setDateOfBirth(dateOfBirth);
        if (countryCode) setSelectedFlagCountry(countryCode);
        setSelectedUserType(clientType);
        setLoader(false);
      }
    }
  }, [userData, permissionName]);

  useEffect(() => {
    if (userEditted) {
      setLoader(false);
    }
  }, [userEditted]);

  const handleUserTypeChange = async () => {
    if (selectedUserType === 1) {
      const data = {
        clientType: 2,
      };
      await dispatch(editUser(user._id, data));
    } else {
      const data = {
        clientType: 1,
      };
      await dispatch(editUser(user._id, data));
    }
  };

  const padTo2Digits = (num) => num.toString().padStart(2, '0');

  const formatDate = (date) => (
    `${[
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-')
    } ${
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(':')}`
  );
  
  return (
    loader ? (<FullPageTransparentLoader />) : user && user ? (
      <div className="content-wrapper right-content-wrapper">
        <div className="content-box">
          <div className="crmuser-slider-block">
            <h5>
              {user.firstName && user.lastName ? ` ${user.firstName} ${user.lastName}` : ' Loading...'}
            </h5>
            {user.countryCode ? (
              <span style={{ color: '#fff' }}>
                {formatDate(getLocalDataByCountryCode(user?.countryCode))}
              </span>
            ) : null}
            <div style={{ display: 'flex', gap: '5px' }}>
              {user.clientType === 2 ? (
                <div className="Domote-botton" onClick={() => handleUserTypeChange()}>
                  Demote to Lead
                </div>
              ) : null}

              {user.clientType === 1 ? (
                <div className="Promote-botton" onClick={() => handleUserTypeChange()}>
                  Promote to Client
                </div>
              ) : null}
              <Link to={prevUser ? `/user-detail/${prevUser?._id}` : null}>
                Previous
              </Link>
              <Link to={nextUser ? `/user-detail/${nextUser?._id}` : null}>
                Next
              </Link>

            </div>
          </div>

          <div style={{ color: 'white', width: '100%' }}>
            <div className="user-details-tabs">
              <div
                className={activeTab === 'edit' ? 'tab-active' : 'tab'}
                onClick={() => setActveTab('edit')}
              >
                Edit User
              </div>
              <div
                className={activeTab === 'accounts' ? 'tab-active' : 'tab'}
                onClick={() => setActveTab('accounts')}
              >
                Accounts
              </div>
              <div
                className={activeTab === 'deposits' ? 'tab-active' : 'tab'}
                onClick={() => setActveTab('deposits')}
              >
                Deposits
              </div>
              <div
                className={activeTab === 'withdrawals' ? 'tab-active' : 'tab'}
                onClick={() => setActveTab('withdrawals')}
              >
                Withdrawals
              </div>
              <div
                className={activeTab === 'active-orders' ? 'tab-active' : 'tab'}
                onClick={() => setActveTab('active-orders')}
              >
                Open Orders
              </div>
              <div
                className={activeTab === 'trade-history' ? 'tab-active' : 'tab'}
                onClick={() => setActveTab('trade-history')}
              >
                Orders History
              </div>
              {/* <div
                className={activeTab === 'exchange-list' ? 'tab-active' : 'tab'}
                onClick={() => setActveTab('exchange-list')}
              >
                Exchange List
              </div> */}
              {isUserCanSeeSaving && (
                <div
                  className={activeTab === 'saving-history' ? 'tab-active' : 'tab'}
                  onClick={() => setActveTab('saving-history')}
                >
                  Saving History
                </div>
              )}
              {isUserCanSeeTradeSettings && (
                <div
                  className={activeTab === 'trading-settings' ? 'tab-active' : 'tab'}
                  onClick={() => setActveTab('trading-settings')}
                >
                  Trading Settings
                </div>
              )}
            </div>
            <div>

              <br />
              {
                isUserCanEditUser && (
                  (activeTab === 'edit' && <EditUser />)
                  || (activeTab === 'accounts' && <Accounts userId={user?._id} />)
                  || (activeTab === 'deposits' && <Deposit />)
                  || (activeTab === 'withdrawals' && <WithdrawalComponent />)
                  || (activeTab === 'active-orders' && <ActiveOrder />)
                  || (activeTab === 'exchange-list' && <ExchangeList />)
                  || (activeTab === 'trade-history' && <TradeHistory />)
                  || (activeTab === 'saving-history' && isUserCanSeeSaving && <SavingInvestmentsDatatable userId={user?._id} />)
                  || (activeTab === 'trading-settings' && isUserCanSeeTradeSettings && <TradingSettings />)
                )
              }
            </div>
          </div>

        </div>
      </div>
    ) : null
  );
}

export default UserDetail;
