import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone, faCircleInfo, faTimes } from '@fortawesome/free-solid-svg-icons';
import { RangeModalFilter } from '../pages/PendingWithdraws/modalFilters';
import { omitColumn, setTransactionActionColumn } from './helper';
import { ModalFilters } from '../components';
import { toFixed } from '../helpers/utils';

const currencyFormatter = require('currency-formatter');

export const DepositColumnsSchema = (
  formatDate, 
  getRateInUsdt, 
  copyReferral, 
  resolveCurrentDepositTransaction, 
  handleRevertTransaction,
  statusesFilter,
  setStatusesFilter,
  amountRange,
  setAmountRange,
  currencyFilter,
  setCurrencyFilter,
  depositTypeFilter,
  setDepositTypeFilter,
  currencies,
  columnConfig,
  filters,
  setFilters,
  permissionName,
  updateIsReal,
  handleDeleteTransaction,
) => {
  const dispatch = useDispatch();
  const [activeInfo, setActiveInfo] = useState(null);

  const handleTransactionInfoModalToggle = (id) => {
    if (activeInfo === id) setActiveInfo(null);
    else setActiveInfo(id);
  };

  const depositColumns = [
    {
      name: 'Received At',
      selector: (row) => formatDate(new Date(row?.createdAt)),
      sortable: true,
      minWidth: '200px',
      omit: omitColumn(columnConfig, 'Received At'),
    },
    {
      name: (
        <ModalFilters
          data={currencies} 
          filters={currencyFilter} 
          setFilters={setCurrencyFilter} 
          tabName="Currency"
          searchParams={{ id: 'symbol', params: ['name', 'symbol'], optionsName: ['symbol'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      selector: (row) => row?.currency,
      omit: omitColumn(columnConfig, 'Currency'),
    },
    {
      name: 'Amount',
      selector: (row) => row.amount,
      sortable: true,
      minWidth: '200px',
      omit: omitColumn(columnConfig, 'Amount'),
    },
    {
      name: 'In USD',
      selector: (row) => parseFloat(row?.amount).toFixed(2),
      cell: (row) => {
        const usdtValue = getRateInUsdt(row?.currency, row?.amount);
        // eslint-disable-next-line no-restricted-globals
        if (usdtValue && !isNaN(usdtValue)) {
          return (currencyFormatter.format(toFixed(usdtValue, 2), { code: 'USD' }));
        }

        return '-';
      },
      sortable: true,
      minWidth: '150px',
      omit: omitColumn(columnConfig, 'In USD'),
    },
    {
      name: 'Type',
      cell: ({ balanceType }) => (balanceType ? 'Deposit' : 'Credit'),
      sortable: true,
      minWidth: '200px',
      omit: omitColumn(columnConfig, 'Type'),
    },
    {
      name: 'To',
      selector: (row) => (
        row?.walletAddress
          ? (
            <CopyToClipboard text={row?.walletAddress}>
              <span>
                {row?.walletAddress.slice(0, 4)}
                ...
                {row?.walletAddress.slice((row?.walletAddress.length || 0) - 4, row?.walletAddress.length)}
                <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="ms-2" />
              </span>
            </CopyToClipboard>
          ) : '-'
      ),
      minWidth: '200px',
      omit: omitColumn(columnConfig, 'To'),
    },
    {
      name: 'Account#',
      selector: ({ account }) => (
        <CopyToClipboard text={account.customId}>
          <span>
            <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
            { account.customId }
          </span>
        </CopyToClipboard>
      ),
      minWidth: '200px',
      omit: omitColumn(columnConfig, 'Account'),
    },
    {
      name: (
        <ModalFilters
          data={[{ name: 'Pending', _id: '0' }, { name: 'Completed', _id: '1' }, { name: 'Declined', _id: '2' }]}
          filters={statusesFilter}
          setFilters={setStatusesFilter}
          tabName="Status" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      selector: (row) => (
        <div 
          onMouseLeave={() => {
            setActiveInfo(null);
          }}
        >
          {row?.isResolved === 0 ? (
            <span className="badge rounded-pill bg-warning">Pending</span>
          ) : row?.isResolved === 1 ? (
            <span className="badge rounded-pill bg-success">Completed</span>
          ) : (
            <span className="badge rounded-pill bg-danger">Declined</span>
          )}
          <span>
            <FontAwesomeIcon
              icon={faCircleInfo}
              className="ms-2"
              size="lg"
              color={row.additionalInfo ? '#6E7F95' : '#4a5668'}
              style={{ cursor: 'pointer' }}
              onMouseEnter={() => {
                handleTransactionInfoModalToggle(row._id);
              }}
            />
            {activeInfo === row._id && row.additionalInfo ? (
              <div className="withdrawsInfoModal">
                <FontAwesomeIcon
                  icon={faTimes}
                  size="lg"
                  color="#6E7F95"
                  style={{
                    cursor: 'pointer', position: 'absolute', right: '10px', top: '10px',
                  }}
                  onClick={() => {
                    setActiveInfo(null);
                  }}
                />
                <p className="withdrawsInfoModal-content">
                  {row.additionalInfo}
                </p>
                
              </div>
            ) : null}
          </span>
        </div>
      ),
      omit: omitColumn(columnConfig, 'Status'),
    },
    {
      name: (
        <ModalFilters
          data={[{ name: 'Real', _id: 0, value: true }, { name: 'Fake', _id: 1, value: false }]}
          filters={depositTypeFilter}
          setFilters={setDepositTypeFilter}
          tabName="Real/Fake" 
          searchParams={{ id: 'value', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      selector: (row) => (row.isReal !== undefined ? (row.isReal === true ? <span className="clr-green">Real</span> : <span className="clr-red">Fake</span>) : '-'),
      minWidth: '150px',
      omit: omitColumn(columnConfig, 'Type'),
    },
    {
      name: 'Action',
      selector: (row) => setTransactionActionColumn(
        row, 
        permissionName,
        resolveCurrentDepositTransaction,
        updateIsReal,
        handleRevertTransaction,
        handleDeleteTransaction,
        false,
        dispatch,
      ),
      minWidth: '300px',
      omit: omitColumn(columnConfig, 'Action'),
    },
  ];

  return depositColumns;
};
