/* eslint-disable react/react-in-jsx-scope */
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CryptoCharts from './Components/CryptoCharts';
import MiscellaneousDetails from './Components/MiscellaneousDetails';
import DepositDetail from './Components/DepositDetails';

function Dashboard() {
  const history = useHistory();
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const isUserCanSeeDashboard = permissionName && permissionName.includes('dashboard');

  useEffect(() => {
    if (permissionName && permissionName.length && !permissionName.includes('dashboard')) {
      history.push('/leads');
    }
  }, [permissionName]);

  return (
    isUserCanSeeDashboard && (
      <div className="content-wrapper right-content-wrapper">
        <div className="dashboard-content-box">
          <div className="row mb-3">
            <div className="col-12">
              <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                <div className="page-heading flex-grow-1">
                  <h4 className="mb-1">Account Balances</h4>
                </div>
              </div>
            </div>
          </div>
          <CryptoCharts />
          <MiscellaneousDetails />
          <DepositDetail />
        </div>
      </div>
    )
  );
}

export default Dashboard;
