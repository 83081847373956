import { toast } from 'react-toastify';
import * as actionTypes from './smtpPresetsTypes';
import { apiHelper } from '../apiHelper';

const setIsSmtpPresetsLoadingCreator = (status) => ({
  type: actionTypes.SET_IS_PRESETS_LOADING,
  payload: status,
});

const setTotalCountCreator = (totalCount) => ({
  type: actionTypes.SET_TOTAL_COUNT,
  payload: totalCount,
});

const setSmtpPresetsCreator = (presets) => ({
  type: actionTypes.SET_SMTP_PRESETS,
  payload: presets,
});

export const setUserSmtpPresetsCreator = (presets) => ({
  type: actionTypes.SET_USER_SMTP_PRESETS,
  payload: presets,
});

const addUserSmtpPresetsCreator = (presets) => ({
  type: actionTypes.ADD_USER_SMTP_PRESETS,
  payload: presets,
});

export const addSmtpPreset = (smtpPreset) => ({
  type: actionTypes.ADD_SMTP_PRESET,
  payload: smtpPreset,
});

export const updateSmtpPreset = (smtpPreset) => ({
  type: actionTypes.UPDATE_SMTP_PRESET,
  payload: smtpPreset,
});

export const removeSmtpPreset = (id) => ({
  type: actionTypes.DELETE_SMTP_PRESET,
  payload: id,
});

export const removeUserSmtpPreset = (id) => ({
  type: actionTypes.DELETE_USER_SMTP_PRESET,
  payload: id,
});


export const getSmtpPresets = () => ({
  type: actionTypes.GET_SMTP_PRESETS,
});

export const setSelectedUserPreset = (preset) => ({
  type: actionTypes.SET_SELECTED_USER_SMTP_PRESET,
  payload: preset,
});


// filters
export const setSmtpAgentFilterCreator = (value) => ({
  type: actionTypes.SET_SMTP_AGENT_SEARCH_FILTER,
  payload: value,
});

export const setSmtpProviderFilterCreator = (value) => ({
  type: actionTypes.SET_SMTP_PROVIDER_SEARCH_FILTER,
  payload: value,
});

export const setSmtpEmailFilterCreator = (value) => ({
  type: actionTypes.SET_SMTP_EMAIL_SEARCH_FILTER,
  payload: value,
});

export const setSmtpPasswordFilterCreator = (value) => ({
  type: actionTypes.SET_SMTP_PASSWORD_SEARCH_FILTER,
  payload: value,
});

export const setSmtpDescriptionFilterCreator = (value) => ({
  type: actionTypes.SET_SMTP_DESCRIPTION_SEARCH_FILTER,
  payload: value,
});


export const fetchSmtpPresetsByUserId = (crmUserId) => async (dispatch) => {
  try {
    const response = await apiHelper('get', `/api/smtp-presets/user/${crmUserId}`);

    if (response?.data && response?.data?.response) {
      const presets = response?.data?.response ?? [];

      dispatch(setUserSmtpPresetsCreator(presets));

      if (presets.length) {
        const smtpPresetLocalData = localStorage.getItem('Header/smtp-preset');
        
        if (!smtpPresetLocalData) {
          dispatch(setSelectedUserPreset(presets[0]));
        }
      }
    } else {
      dispatch(setUserSmtpPresetsCreator([]));
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const fetchSmtpPresets = (page = 1, rowsPerPage = 25, filters = {}) => async (dispatch) => {
  dispatch(setIsSmtpPresetsLoadingCreator(true));

  const params = {
    page,
    limit: rowsPerPage,
  };
  
  if (filters.agent) params.agent = filters.agent ?? '';
  if (filters.provider) params.provider = filters.provider ?? '';
  if (filters.email) params.email = filters.email ?? '';
  if (filters.password) params.password = filters.password ?? '';
  if (filters.description) params.description = filters.description ?? '';

  try {
    const response = await apiHelper('get', '/api/smtp-presets', '', params);

    if (response?.data && response?.data?.allSmtpPresets) {
      const result = response?.data?.allSmtpPresets[0] ?? {};

      const allSmtpPresets = result?.paginatedResult ?? [];
      const totalCount = result?.totalCount[0]?.count ?? 25;

      dispatch(setTotalCountCreator(totalCount));
      dispatch(setSmtpPresetsCreator(allSmtpPresets));
    } else {
      dispatch(setSmtpPresetsCreator([]));
    }

    dispatch(setIsSmtpPresetsLoadingCreator(false));
  } catch (error) {
    toast.error(error.response.message);
    dispatch(setIsSmtpPresetsLoadingCreator(false));
  }
};

export const createSmtpPreset = (authorId, data, hideModal) => async (dispatch) => {
  try {
    const response = await apiHelper('post', '/api/smtp-presets/', data);

    if (response?.data) {
      const { preset, message } = response.data;
      hideModal();
      toast.success(message);
      dispatch(addSmtpPreset(preset));

      if (authorId === preset.agent) {
        dispatch(addUserSmtpPresetsCreator(preset));
      }
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const editSmtpPreset = (id, data, hideModal) => async (dispatch, getState) => {
  try {
    const response = await apiHelper('put', `/api/smtp-presets/${id}`, data);
    const { crmUser: { crmUserInfo } } = getState();

    if (response?.data) {
      const { preset, message } = response.data;
      hideModal();
      toast.success(message);
      dispatch(updateSmtpPreset(preset));
      dispatch(fetchSmtpPresetsByUserId(crmUserInfo._id));
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const deleteSmtpPreset = (id) => async (dispatch) => {
  try {
    const response = await apiHelper('delete', `/api/smtp-presets/${id}`, '');

    if (response?.data) {
      const {
        result: { _id }, 
        message,
      } = response.data;
      toast.success(message);
      dispatch(removeSmtpPreset(_id));
      dispatch(removeUserSmtpPreset(_id));
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};
