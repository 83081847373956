// Auth Components
import LoginPage from '../pages/Authentication/Login';
import ForgetPasswords from '../pages/ForgetPasswords';
import ForgotPassEmail from '../pages/ForgetPassEmail/ForgetPassEmail';

// Private Components
import ChangePassword from '../pages/ChangePassword/ChangePassword';
import Dashboard from '../pages/Dashboard';
import Users from '../pages/Users/Users';
import AddUser from '../pages/Users/AddUser';
import EditUser from '../pages/Users/EditUser';
import UserProfile from '../pages/Users/UserProfile';
import UserDetail from '../pages/Users/UserDetail';
import Roles from '../pages/Roles/Roles';
import AddRole from '../pages/Roles/AddRole';
import EditRole from '../pages/Roles/EditRole';
import AddPermission from '../pages/Permissions/AddPermission';
import Permissions from '../pages/Permissions/Permissions';
import Transactions from '../pages/Transactions/Transactions';
import ExternalTransactions from '../pages/Transactions/ExternalTransactions/ExternalTransactions';
import Currency from '../pages/Currency/Currency';
import AddCurrency from '../pages/Currency/AddCurrency';
import TransactionFee from '../pages/Transactions/TransactionFee';
import SetTransactionFee from '../pages/Transactions/SetTransactionFee';
import EditTransactionFee from '../pages/Transactions/EditTransactionFee';
import WithdrawFee from '../pages/WithdrawFees/WithdrawFee';
import SetWithdrawFee from '../pages/WithdrawFees/SetWithdrawFee';
import EditWithdrawFee from '../pages/WithdrawFees/EditWithdrawFee';
import Leverage from '../pages/Leverage/Leverage';
import SetLeverage from '../pages/Leverage/SetLeverage';
import EditLeverage from '../pages/Leverage/EditLeverage';
import SubAdmins from '../pages/SubAdmin/SubAdmins';
import AddSubAdmin from '../pages/SubAdmin/AddSubAdmin';
import EditSubAdmin from '../pages/SubAdmin/EditSubAdmin';
import PartnerList from '../pages/Partner/PartnerList';
import DeletedUsers from '../pages/Users/DeletedUsers';
import DeletedSubAdmins from '../pages/SubAdmin/DeletedSubAdmins';
import PermissionPerRole from '../pages/Roles/PermissionPerRole';
import Settings from '../pages/Settings/Settings';
import KycAml from '../pages/KycAml/KycAml';
import PendingWithdraws from '../pages/PendingWithdraws/PendingWithdraws';
import WithdrawCurrency from '../pages/WithdrawCurrency/WithdrawCurrency';
import PermissionsModules from '../pages/PermissionsModule/PermissionsModules';
import AddPermissionsModule from '../pages/PermissionsModule/AddPermissionsModule';
import OpenOrders from '../pages/OpenOrders/OpenOrders';
import OrdersHistory from '../pages/OrdersHistory/OrdersHistory';
import Leads from '../pages/Users/Leads';
import Affiliate from '../pages/AffiliateMonitor/Affiliate';
import EmailTemplates from '../pages/EmailTemplates/EmailTemplates';
import AffiliateDetails from '../pages/AffiliateMonitor/AffiliateDetails';
import FiatCurrency from '../pages/FiatCurrency/FiatCurrency';
import AddFiatCurrency from '../pages/FiatCurrency/AddFiatCurrency';
import SalesStatus from '../pages/SalesStatus/SalesStatus';
import AdminBankAccount from '../pages/PSP/AdminBankAccount';
import AddAdminBankAccount from '../pages/PSP/AddAdminBankAccount';
import EditAdminBankAccount from '../pages/PSP/EditAdminBankAccount';
import AddAdminAddress from '../pages/PSP/AddAdminAddress';
import AdminAddress from '../pages/PSP/AdminAddress';
import EditAdminAddress from '../pages/PSP/EditAdminAddress';
import PspOthers from '../pages/PSP/PspOthers';
import Notifications from '../pages/notifications/notifications';
import CRMUsers from '../pages/SubAdmin/CRMUsers';
import ChatBox from '../pages/ChatBox/Chatbox';
import WhitelistIP from '../pages/Security/WhitelistIP';
import Hierarchy from '../pages/Hierarchy/Hierarchy';
import AddCRMUser from '../pages/SubAdmin/AddCRMUser';
import EditCRMUser from '../pages/SubAdmin/EditCRMUser';
import TwoFactorAuthentication from '../pages/TwoFactorAuthentication/TwoFactorAuthentication';
import CRMActivity from '../pages/Activity/CRMActivity';
import ClientActivity from '../pages/Activity/ClientActivity';
import DeletedTransactions from '../pages/Transactions/DeletedTransactions';
import Calendar from '../pages/Calendar/Calendar';
import CfdAccounts from '../pages/CfdAccounts/CfdAccounts';
import Saving from '../pages/Saving/Saving';
import AddSaving from '../pages/Saving/AddSaving';
import EditSaving from '../pages/Saving/EditSaving';
import SavingHistory from '../pages/Saving/SavingHistory';
import { CfdSettings } from '../pages/CfdSettings';
import SmtpPresets from '../pages/SmtpPresets/SmtpPresets';
import { Voip } from '../pages/Voip/Voip';

const routes = [
  {
    path: '/login', exact: true, name: 'Login', layout: 'AuthLayoutRoute', component: LoginPage,
  },
  {
    path: '/forgot-password/:token', exact: true, name: 'Forgot Password', layout: 'AuthLayoutRoute', component: ForgetPasswords,
  },
  {
    path: '/forgot-password-email', exact: true, name: 'Forgot Password Email', layout: 'AuthLayoutRoute', component: ForgotPassEmail,
  },
  {
    path: '/change-password', exact: true, name: 'Change Password', layout: 'PrivateLayoutRoute', component: ChangePassword,
  },
  {
    path: '/', exact: true, name: 'Dashboard', layout: 'PrivateLayoutRoute', component: Dashboard,
  },
  {
    path: '/dashboard', exact: true, name: 'Dashboard', layout: 'PrivateLayoutRoute', component: Dashboard,
  },
  {
    path: '/notifications', exact: true, name: 'Notifications', layout: 'PrivateLayoutRoute', component: Notifications,
  },
  {
    path: '/users', exact: true, name: 'Users', layout: 'PrivateLayoutRoute', component: Users,
  },
  {
    path: '/leads', exact: true, name: 'Leads', layout: 'PrivateLayoutRoute', component: Leads,
  },
  {
    path: '/crm-users', exact: true, name: 'CRMUsers', layout: 'PrivateLayoutRoute', component: CRMUsers,
  },
  {
    path: '/affiliate', exact: true, name: 'Affiliate', layout: 'PrivateLayoutRoute', component: Affiliate,
  },
  {
    path: '/email-templates', exact: true, name: 'EmailTemplates', layout: 'PrivateLayoutRoute', component: EmailTemplates,
  },
  {
    path: '/smtp-presets', exact: true, name: 'SMTP', layout: 'PrivateLayoutRoute', component: SmtpPresets,
  },
  {
    path: '/add-user', exact: true, name: 'Add User', layout: 'PrivateLayoutRoute', component: AddUser,
  },
  {
    path: '/edit-user/:id', exact: true, name: 'Edit User', layout: 'PrivateLayoutRoute', component: EditUser,
  },
  {
    path: '/user-profile', exact: true, name: 'User Profile', layout: 'PrivateLayoutRoute', component: UserProfile,
  },
  {
    path: '/user-detail/:id', exact: true, name: 'User Detail', layout: 'PrivateLayoutRoute', component: UserDetail,
  },
  {
    path: '/affiliate-detail/:id', exact: true, name: 'User Detail', layout: 'PrivateLayoutRoute', component: AffiliateDetails,
  },
  {
    path: '/roles', exact: true, name: 'Roles', layout: 'PrivateLayoutRoute', component: Roles,
  },
  {
    path: '/add-role', exact: true, name: 'Add Role', layout: 'PrivateLayoutRoute', component: AddRole,
  },
  {
    path: '/edit-role/:id', exact: true, name: 'Edit Role', layout: 'PrivateLayoutRoute', component: EditRole,
  },
  {
    path: '/permissions', exact: true, name: 'Permissions', layout: 'PrivateLayoutRoute', component: Permissions,
  },
  {
    path: '/add-permission', exact: true, name: 'Add Permission', layout: 'PrivateLayoutRoute', component: AddPermission,
  },
  {
    path: '/permissions-modules', exact: true, name: 'Permissions Modules', layout: 'PrivateLayoutRoute', component: PermissionsModules,
  },
  {
    path: '/add-permissions-module', exact: true, name: 'Add Permissions Module', layout: 'PrivateLayoutRoute', component: AddPermissionsModule,
  },
  {
    path: '/kyc-aml', exact: true, name: 'KycAml', layout: 'PrivateLayoutRoute', component: KycAml,
  },
  {
    path: '/pending-withdraws', exact: true, name: 'PendingWithdraws', layout: 'PrivateLayoutRoute', component: PendingWithdraws,
  },
  {
    path: '/transactions', exact: true, name: 'Transactions', layout: 'PrivateLayoutRoute', component: Transactions,
  },
  {
    path: '/external-transactions', exact: true, name: 'External Transactions', layout: 'PrivateLayoutRoute', component: ExternalTransactions,
  },
  {
    path: '/currency', exact: true, name: 'Currencies', layout: 'PrivateLayoutRoute', component: Currency,
  },
  {
    path: '/withdraw-currency', exact: true, name: 'Withdraw Currency', layout: 'PrivateLayoutRoute', component: WithdrawCurrency,
  },
  {
    path: '/add-currency', exact: true, name: 'Add Currency', layout: 'PrivateLayoutRoute', component: AddCurrency,
  },
  {
    path: '/conversion-fee', exact: true, name: 'Conversion Fee', layout: 'PrivateLayoutRoute', component: TransactionFee,
  },
  {
    path: '/set-conversion-fee', exact: true, name: 'Set Conversion Fee', layout: 'PrivateLayoutRoute', component: SetTransactionFee,
  },
  {
    path: '/edit-conversion-fee/:id', exact: true, name: 'Edit Conversion Fee', layout: 'PrivateLayoutRoute', component: EditTransactionFee,
  },
  {
    path: '/withdraw-fee', exact: true, name: 'Withdraw Fees', layout: 'PrivateLayoutRoute', component: WithdrawFee,
  },
  {
    path: '/set-withdraw-fee', exact: true, name: 'Set Withdraw Fees', layout: 'PrivateLayoutRoute', component: SetWithdrawFee,
  },
  {
    path: '/edit-withdraw-fee/:id', exact: true, name: 'Edit Withdraw Fees', layout: 'PrivateLayoutRoute', component: EditWithdrawFee,
  },
  {
    path: '/leverage', exact: true, name: 'Leverages', layout: 'PrivateLayoutRoute', component: Leverage,
  },
  {
    path: '/set-leverage', exact: true, name: 'Set Leverage', layout: 'PrivateLayoutRoute', component: SetLeverage,
  },
  {
    path: '/edit-leverage/:id', exact: true, name: 'Edit Leverage', layout: 'PrivateLayoutRoute', component: EditLeverage,
  },
  {
    path: '/sub-admins', exact: true, name: 'Sub Admins', layout: 'PrivateLayoutRoute', component: SubAdmins,
  },
  {
    path: '/add-sub-admin', exact: true, name: 'Add Sub Admin', layout: 'PrivateLayoutRoute', component: AddSubAdmin,
  },
  {
    path: '/add-admin', exact: true, name: 'Add CRM User', layout: 'PrivateLayoutRoute', component: AddCRMUser,
  },
  {
    path: '/edit-admin/:id', exact: true, name: 'Edit CRM User', layout: 'PrivateLayoutRoute', component: EditCRMUser,
  },
  {
    path: '/edit-sub-admin/:id', exact: true, name: 'Edit Sub Admin', layout: 'PrivateLayoutRoute', component: EditSubAdmin,
  },
  {
    path: '/partner-list', exact: true, name: 'Partners', layout: 'PrivateLayoutRoute', component: PartnerList,
  },
  {
    path: '/deleted-users', exact: true, name: 'Deleted Users', layout: 'PrivateLayoutRoute', component: DeletedUsers,
  },
  {
    path: '/deleted-sub-admins', exact: true, name: 'Deleted Sub Admins', layout: 'PrivateLayoutRoute', component: DeletedSubAdmins,
  },
  {
    path: '/change-password', exact: true, name: 'Change Password', layout: 'PrivateLayoutRoute', component: ChangePassword,
  },
  {
    path: '/permission-per-role/:id', exact: true, name: 'Role Permissions', layout: 'PrivateLayoutRoute', component: PermissionPerRole,
  },
  {
    path: '/open-orders', exact: true, name: 'Open Order', layout: 'PrivateLayoutRoute', component: OpenOrders,
  },
  {
    path: '/orders-history', exact: true, name: 'Orders History', layout: 'PrivateLayoutRoute', component: OrdersHistory,
  },
  {
    path: '/fiat-currency', exact: true, name: 'Currencies', layout: 'PrivateLayoutRoute', component: FiatCurrency,
  },
  {
    path: '/add-fiat-currency', exact: true, name: 'Add Currency', layout: 'PrivateLayoutRoute', component: AddFiatCurrency,
  },
  {
    path: '/statuses', exact: true, name: 'Statuses', layout: 'PrivateLayoutRoute', component: SalesStatus,
  },
  {
    path: '/bank-accounts', exact: true, name: 'Statuses', layout: 'PrivateLayoutRoute', component: AdminBankAccount,
  },
  {
    path: '/psp-others', exact: true, name: 'PspOthers', layout: 'PrivateLayoutRoute', component: PspOthers,
  },
  {
    path: '/add-bank-account', exact: true, name: 'Add Status', layout: 'PrivateLayoutRoute', component: AddAdminBankAccount,
  },
  {
    path: '/edit-bank-account/:id', exact: true, name: 'Edit Status', layout: 'PrivateLayoutRoute', component: EditAdminBankAccount,
  },
  {
    path: '/add-admin-address', exact: true, name: 'Add Status', layout: 'PrivateLayoutRoute', component: AddAdminAddress,
  },
  {
    path: '/admin-addresses', exact: true, name: 'Statuses', layout: 'PrivateLayoutRoute', component: AdminAddress,
  },
  {
    path: '/edit-admin-address/:id', exact: true, name: 'Edit Status', layout: 'PrivateLayoutRoute', component: EditAdminAddress,
  },
  {
    path: '/chatbox/:id?', exact: true, name: 'Chat Box', layout: 'PrivateLayoutRoute', component: ChatBox,
  },
  {
    path: '/whitelist-ip', exact: true, name: 'IP Whitelist', layout: 'PrivateLayoutRoute', component: WhitelistIP,
  },
  {
    path: '/hierarchy', exact: true, name: 'Hierarchy', layout: 'PrivateLayoutRoute', component: Hierarchy,
  },
  {
    path: '/additional-security', exact: true, name: 'Two Factor Authentication', layout: 'PrivateLayoutRoute', component: TwoFactorAuthentication,
  },
  {
    path: '/crm-activity', exact: true, name: 'CRM Activity', layout: 'PrivateLayoutRoute', component: CRMActivity,
  },
  {
    path: '/client-activity', exact: true, name: 'Client Activity', layout: 'PrivateLayoutRoute', component: ClientActivity,
  },
  {
    path: '/symbol-settings', exact: true, name: 'Forex Currency', layout: 'PrivateLayoutRoute', component: CfdSettings, 
  },
  {
    path: '/deleted-transactions', exact: true, name: 'Deleted Transaction', layout: 'PrivateLayoutRoute', component: DeletedTransactions,
  },
  {
    path: '/calendar', exact: true, name: 'Calendar', layout: 'PrivateLayoutRoute', component: Calendar,
  },
  {
    path: '/cfd-accounts', exact: true, name: 'Cfd Accounts', layout: 'PrivateLayoutRoute', component: CfdAccounts,
  },
  {
    path: '/saving', exact: true, name: 'Saving', layout: 'PrivateLayoutRoute', component: Saving,
  },
  {
    path: '/add-saving', exact: true, name: 'Saving', layout: 'PrivateLayoutRoute', component: AddSaving,
  },
  {
    path: '/edit-saving/:id', exact: true, name: 'Saving', layout: 'PrivateLayoutRoute', component: EditSaving,
  },
  {
    path: '/saving-history', exact: true, name: 'Saving History', layout: 'PrivateLayoutRoute', component: SavingHistory,
  },
  {
    path: '/voip', exact: true, name: 'VoIP', layout: 'PrivateLayoutRoute', component: Voip,
  },
];

export default routes;
