import React from 'react';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { faClone, faExternalLink, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import { RangeModalDebounceFilter } from '../../pages/Transactions/modalFilters';
import { ModalFilters } from '../../components';
import { omitColumn, formatDate } from '../helper';

import './style.css';


export const CfdAccountsSchema = (
  columnConfig,
  amountRange,
  setAmountRange,
  cfdQuery,
  setCfdQuery,
  currencies,
  assetFilter,
  setAssetFilter,
  creditRange,
  setCreditRange,
  permissionName,
  balanceRange,
  setBalanceRange,
  setDebounceAmountRange,
  customIdFilter, 
  setCustomIdFilter,
  fullNameFilter, 
  setFullNameFilter,
  handleDeleteCfdAccounts,
  setInputFieldError,
  inputFieldError,
  accountTypes,
) => {
  const dispatch = useDispatch();
  const isUserCanSeeCfdAccounts = permissionName.includes('cfd_accounts');
  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  const handleValidationInputs = ({ target: { value } }, nameField) => {
    if (value.length < 3 && value !== '') setInputFieldError((prev) => ({ ...prev, errorMessage: 'Must be more than 3 symbols', [nameField]: true }));
    else setInputFieldError((prev) => ({ ...prev, errorMessage: '', [nameField]: false }));
    if (nameField === 'fullNameIsNotValid') setFullNameFilter(value);
  };
  
  const columns = [
    {
      name: (
        <div className="cfdAccount_idWrapper">
          <p className="cfdAccount_idWrapper_text">ID</p>
          <input 
            type="text"
            placeholder="Search"
            autoComplete="off"
            className="cfdAccount_input"
            style={inputFieldError.transactionIdIsNotValid ? { border: '1px solid red' } : { border: 'none' }}
            name="id"
            value={customIdFilter || ''}
            onChange={(e) => setCustomIdFilter(e.target.value)}
          />
          <span className="cfdAccount_errorInputWrapper" />
        </div>),
      width: '200px',
      omit: omitColumn(columnConfig, 'ID'), 
      cell: ({ customId }) => (
        <CopyToClipboard text={customId}>
          <span className="cfdSccounts-row_Id">
            <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
            { customId }
          </span>
        </CopyToClipboard>
      ),
    },
    {
      name: (
        <div className="cfdAccount_idWrapper">
          <p className="cfdAccount_idWrapper_text">Client</p>
          <input 
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="client"
            className="cfdAccount_input"
            value={fullNameFilter || ''}
            onChange={(e) => handleValidationInputs(e, 'fullNameIsNotValid')}
          />
          <span className="cfdAccount_errorInputWrapper">
            {inputFieldError.fullNameIsNotValid && (
              <p className="cfdAccount_errorInput">
                {inputFieldError.errorMessage}
              </p>
            )}
          </span> 
        </div>),
      width: '200px',
      omit: omitColumn(columnConfig, 'Client'), 
      cell: ({ user, userId }) => ( 
        <div className="cfdSccounts-row_ClientWrapper">
          { 
            (user && user.firstName && user.lastName) ? (
              <Link to={`/user-detail/${userId}`} target="blank" className="text-white">
                <FontAwesomeIcon icon={faExternalLink} size="xs" />
                <span 
                  className="cfdSccounts-row_fullName"
                >
                  {`${user.firstName} ${user.lastName}`}
                </span>
              </Link>
            ) : (<span>-</span>) 
          }
        </div> 
      ), 
    },
    {
      name: (
        <ModalFilters 
          data={accountTypes} 
          filters={assetFilter} 
          setFilters={setAssetFilter} 
          tabName="Type" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={cfdQuery}
          setUserFilters={setCfdQuery}
        />
      ),
      omit: omitColumn(columnConfig, 'Type'), 
      minWidth: '200px',
      cell: ({ type }) => (type.name),
    },
    {
      name: (
        <RangeModalDebounceFilter
          columnName="Total Amount" 
          value={amountRange ?? [0, 1000000]} 
          setValue={setAmountRange} 
          setFilters={setDebounceAmountRange} 
          filters={{}} 
          filedName="amountRange"
          debounceAmountRange
        />
      ),
      minWidth: '200px',
      omit: omitColumn(columnConfig, 'Amount'), 
      cell: ({ total }) => total?.toFixed(2),
    },
    {
      name: (
        <RangeModalDebounceFilter 
          columnName="Balance" 
          value={balanceRange ?? [0, 1000000]} 
          setValue={setBalanceRange} 
          setFilters={setDebounceAmountRange} 
          filters={{}} 
          filedName="balanceRange"
          debounceAmountRange
        />
      ),
      minWidth: '200px',
      omit: omitColumn(columnConfig, 'Balance'), 
      cell: ({ balance }) => balance.toFixed(2),
    },
    {
      name: (
        <ModalFilters 
          data={currencies} 
          filters={assetFilter} 
          setFilters={setAssetFilter} 
          tabName="Currency" 
          searchParams={{ id: '_id', params: ['name', '_id', 'symbol'], optionsName: ['symbol'] }}
          usersFilters={cfdQuery}
          setUserFilters={setCfdQuery}
        />
      ),
      omit: omitColumn(columnConfig, 'Currency'), 
      minWidth: '200px',
      cell: ({ cfdAccountCurrency, isSaving, currency }) => (!isSaving ? cfdAccountCurrency.symbol : currency.symbol),
    },
    {
      name: ( 
        <RangeModalDebounceFilter
          columnName="Credit" 
          value={creditRange ?? [0, 1000000]} 
          setValue={setCreditRange} 
          setFilters={setCfdQuery} 
          filters={cfdQuery}
          filedName="creditRange"
        />
      ),
      minWidth: '200px',
      omit: omitColumn(columnConfig, 'Credit'), 
      cell: ({ credit, cfdAccountCurrency, isSaving }) => (!isSaving ? `${credit?.toFixed(2)} ${cfdAccountCurrency?.symbol}` : '-'),
    },
    {
      name: 'Leverage',
      minWidth: '180px',
      selector: ({ leverage }) => (leverage || '-'), 
      sortable: true,
    },
    {
      name: 'Lot Step',
      minWidth: '180px',
      selector: ({ lotStep }) => (lotStep || '-'), 
      sortable: true,
    },
    {
      name: 'Lot Size',
      minWidth: '180px',
      selector: ({ lotSize }) => (lotSize || '-'), 
      sortable: true,
    },
    {
      name: 'Commission%',
      minWidth: '180px',
      selector: ({ commission }) => (commission || '-'), 
      sortable: true,
    },
    {
      name: 'Swap Long%',
      minWidth: '180px',
      selector: ({ swapLong }) => (swapLong || '-'), 
      sortable: true,
    },
    {
      name: 'Swap Short%',
      minWidth: '180px',
      selector: ({ swapShort }) => (swapShort || '-'), 
      sortable: true,
    },
    {
      name: 'Created At',
      omit: omitColumn(columnConfig, 'Created At'), 
      cell: ({ createdAt }) => (createdAt ? formatDate(new Date(createdAt)) : '-'),
      width: '200px',
    },
    {
      name: 'Action(s)',
      minWidth: '200px',
      omit: omitColumn(columnConfig, 'Actions'), 
      cell: (row) => (isUserCanSeeCfdAccounts ? (
        <button
          type="button"
          className="btn btn-danger btn-sm me-1 p-1"
          onClick={() => handleDeleteCfdAccounts(row)}
        >
          <FontAwesomeIcon icon={faTrash} className="header-icon" />
        </button>
      ) : (<span>-</span>)),
    },
  ];
  return columns;
};
