import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import FullPageTransparentLoader from '../../FullPageTransparentLoader/fullPageTransparentLoader';
import { getTransactionsByQuery } from '../../../redux/transactions/transactionActions';
import { DepositDetailColumns } from '../../../DataTableSchemas/DepositDetailColumns';

function DepositDetails() {
  const dispatch = useDispatch();

  const [loader] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [tableLoader, setTableLoader] = useState(false);
  const [isPaginationDT, setIsPaginationDT] = useState(false);

  const { paginatedData: transactionsData, totalCount } = useSelector((state) => state?.transaction?.allDeposits);

  const paginationStoredge = 'DataTable_dashboard/pagination'; 

  const setStoredPagination = () => {
    const depositRowsJSON = localStorage.getItem(paginationStoredge);
    if (depositRowsJSON) {
      const filterRows = JSON.parse(depositRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
      setPage(filterRows.page || 1);
    }

    setIsPaginationDT(true);
  };

  useEffect(() => {
    setStoredPagination();
  }, []);

  useEffect(() => {
    async function fetchData() {
      setTableLoader(true);
      setStoredPagination();
      await dispatch(getTransactionsByQuery({
        page, limit: rowsPerPage, query: {}, isDeleted: false, 
      }));
      setTableLoader(false);
    }

    fetchData();
  }, [page, rowsPerPage]);

  const handlePageChange = (currentPage) => {
    localStorage.setItem(paginationStoredge, JSON.stringify({ limit: rowsPerPage, page: currentPage }));
    setPage(currentPage);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, page) => {
    localStorage.setItem(paginationStoredge, JSON.stringify({ limit: currentRowsPerPage, page }));
    setRowsPerPage(currentRowsPerPage);
  };

  const depColumns = DepositDetailColumns(page, rowsPerPage);
  return (
    loader ? (
      <FullPageTransparentLoader />
    ) : (
      <div className="dashboard-tbl-wrapper custom-tbl-wrapper" style={{ zIndex: 0 }}>
        {
          isPaginationDT && (
            <DataTable
              title="Deposits Detail"
              columns={depColumns}
              data={transactionsData}
              fixedHeader
              pagination
              paginationServer
              paginationTotalRows={totalCount}
              paginationPerPage={rowsPerPage}
              paginationRowsPerPageOptions={[25, 50, 100, 500]}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              paginationDefaultPage={page}
              persistTableHead
              highlightOnHover
              theme="solarizedd"
              progressPending={tableLoader}
              progressComponent={<div className="datatable-loader__background" />}
            />
          )
        }
      </div>
    )
  );
}

export default DepositDetails;
